// poppins fonts
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "rsuite/dist/rsuite.css";

:root {
  --color-green: #8cc714;
  --color-light-green: #8cc714;
  --color-black: #323232;
  --color-grey: #777777;
  --color-light-grey: #cccccc;
  --color-white: #ffffff;
  --color-red: #ee5e5e;
}

* {
  outline: none !important;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 50px;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto !important;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  // background-color: #f9f9f9;
  background-color: #f4f4f4;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 50px;
  }
}
.myDate {
  .MuiInputBase-root {
    padding-top: 4px;
    max-height: 58px;
  }
  fieldset {
    border-color: var(--color-light-grey) !important;
    border-radius: 10px;
  }
  &:hover,
  &:focus,
  &:active {
    fieldset {
      border-color: var(--color-green) !important;
    }
  }
}
.error-myDate {
  .MuiInputBase-root {
    padding-top: 4px;
    max-height: 58px;
  }
  fieldset {
    border-color: red !important;
    border-radius: 10px;
  }
  &:hover,
  &:focus,
  &:active {
    fieldset {
      border-color: red !important;
    }
  }
}

.MuiPickersPopper-root,
.MuiDialog-root {
  font-family: "Poppins", sans-serif;

  .MuiPickersLayout-root .MuiPickersLayout-toolbar {
    display: none;
  }

  .MuiButton-text {
    color: var(--color-green);
  }
  .MuiPaper-root {
    .MuiPickersLayout-root {
      .MuiPickersLayout-contentWrapper {
        .MuiDateCalendar-root {
          max-width: 309px;

          .MuiPickersCalendarHeader-root {
            display: grid;
            grid-template-columns: 50px 1fr 50px;

            .MuiPickersCalendarHeader-labelContainer {
              grid-column: 2;
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 70px;

              .MuiPickersFadeTransitionGroup-root {
                text-align: right;
                white-space: nowrap;
                .MuiPickersCalendarHeader-label {
                  margin: 0px;
                }
              }
            }

            .MuiIconButton-edgeEnd {
              position: absolute;
              left: 0;
              grid-column: 1;
              margin-left: 20px;
            }

            .MuiIconButton-edgeStart {
              grid-column: 3;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .MuiPickersFadeTransitionGroup-root {
    .MuiYearCalendar-root {
      .MuiPickersYear-yearButton {
        border-radius: 10px;
        width: 60px;
      }
      .Mui-selected {
        background-color: var(--color-green) !important;
      }
    }
  }

  .MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 0px;

    .MuiPickersDay-root {
      line-height: 0.75rem;
    }

    &.MuiPickersDay-root:not(.Mui-selected) {
      border-color: var(--color-green);
      background-color: var(--color-white) !important;
    }

    &.MuiPickersArrowSwitcher-button {
      background-color: var(--color-green) !important;
      border-radius: 10px;
      padding: 6px 6px;
      color: var(--color-white) !important;
    }

    &.Mui-selected {
      will-change: unset;
      background-color: var(--color-green) !important;
      border-radius: 8px;

      &:hover {
        background-color: var(--color-green) !important;
      }
    }
  }
}

.time-picker {
  .MuiInputBase-root {
    border: 1px solid var(--color-light-grey);
    border-radius: 10px;
    height: 50px;
    &:focus,
    &:hover {
      border-color: var(--color-green) !important;
    }

    fieldset {
      display: none;
    }
  }
  &:focus,
  &:hover {
    .MuiInputBase-root {
      border-color: var(--color-green) !important;
    }
  }
}
.error-time-picker {
  .MuiInputBase-root {
    border: 1px solid red;
    border-radius: 10px;
    height: 50px;
    &:focus,
    &:hover {
      border-color: red !important;
    }

    fieldset {
      display: none;
    }
  }
  &:focus,
  &:hover {
    .MuiInputBase-root {
      border-color: red !important;
    }
  }
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

/* For Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For other browsers */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-green {
  color: var(--color-green) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.text-white {
  color: var(--color-white);
}

.text-grey {
  color: var(--color-grey) !important;
}

.bg-green {
  background-color: var(--color-green);
}

.bg-black {
  background-color: var(--color-black);
}

.bg-white {
  background-color: var(--color-white);
}

.bg-grey {
  background-color: var(--color-grey);
}
.text-center {
  text-align: center;
}
.error-text {
  font-size: 14px !important;
  color: red !important;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif !important;
}

p,
span {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal;
  line-height: 25.89px;
  font-family: "Poppins", sans-serif !important;
}

.btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 30px;
  height: 50px;
  max-width: 300px;
  width: 100%;
  border-radius: 10px !important;
  border: 1px solid var(--color-green);
  font-family: "Poppins", sans-serif;
  text-transform: capitalize !important;
  color: var(--color-white);
  cursor: pointer;

  &.btn-primary {
    background-color: var(--color-green) !important;
    border: 1px solid var(--color-green);
    color: var(--color-white);
    box-shadow: none !important;
  }

  &.btn-secondary {
    background-color: var(--color-grey) !important;
    border: 1px solid var(--color-grey);
    color: var(--color-white);
    box-shadow: none !important;
  }

  &.btn-tertiary {
    background-color: var(--color-black) !important;
    border: 1px solid var(--color-black);
    color: var(--color-white) !important;
    box-shadow: none !important;
  }
}

.form-field {
  .form-control {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .input-label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    .select-field {
      div:nth-child(3) {
        // border: 1px solid var(--color-light-grey) !important;
        min-height: 50px !important;
      }
    }
    .input-field {
      input,
      textarea {
        background: var(--color-white);
        border: 1px solid var(--color-light-grey) !important;
        border-radius: 10px;
        padding: 13px;

        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-grey);
          opacity: 1;
        }

        &:-ms-input-placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-grey);
        }

        &::-ms-input-placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-grey);
        }

        &:focus {
          border: 1px solid var(--color-green);
        }

        &.Mui-disabled {
          background-color: #eeeeee;
          color: var(--color-grey);
        }
      }
    }
    .border-error {
      input,
      textarea {
        background: var(--color-white);
        border: 1px solid red !important;
        border-radius: 10px;
        padding: 13px;
      }
    }
    .select-border-error {
      background: var(--color-back);
      border: 1px solid red !important;
      border-radius: 10px;
      min-height: 50px !important;

    }

    .end-input-icon {
      position: absolute;
      right: 25px;
      bottom: 12px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      font-size: 25px;
    }

    .checkbox-field {
      margin-left: -5px;
      width: fit-content;

      span {
        padding: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 5px;
        color: var(--color-black);

        svg {
          width: 30px;
          height: 30px;
        }

        &.Mui-checked {
          svg {
            color: var(--color-green);
          }
        }
      }
    }
  }
}

// table checkbox
thead {
  span {
    margin-left: -5px !important;
    width: fit-content;
    padding: 0 !important;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 5px;
    color: var(--color-white) !important;

    // background-color: var(--color-white) !important;
    svg {
      fill: var(--color-white);
      width: 30px !important;
      height: 30px !important;
    }

    &.Mui-checked {
      svg {
        fill: var(--color-green);
        color: var(--color-green) !important;
      }
    }
  }
}

tbody {
  .table-checkbox {
    margin-left: -5px !important;
    width: fit-content;
    padding: 0 !important;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 5px;
    color: var(--color-black);

    // background-color: var(--color-white);
    svg {
      fill: var(--color-grey);
      width: 30px !important;
      height: 30px !important;
    }

    &.Mui-checked {
      svg {
        fill: var(--color-green);
        color: var(--color-green) !important;
      }
    }
  }
}

.mask-box-container {
  display: flex;
  .mask-box {
    border-radius: 20px;
    position: relative;
    background: var(--color-grey);
    display: flex;
    padding: 3px;
    .mask {
      // width: 80px;
      height: 30px;
      font-size: 14px;
      border-radius: 20px;
      background: var(--color-green);
      position: absolute;
      transition: all 0.5s ease;

      &.mask-1 {
        width: 80px;
      }
      &.mask-2 {
        width: 100px;
      }
    }
    .mask-btn {
      border-radius: 20px;
      height: 30px;
      font-size: 14px;
      font-weight: 400;
      transition: all 0.2s 0.1s ease;
      text-transform: capitalize;
      color: var(--color-white);

      &.mask-btn-1 {
        width: 80px;
      }
      &.mask-btn-2 {
        width: 100px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

// modal style
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-white);
  min-height: calc(100% - 64px);
  border-radius: 10px;
  width: calc(100% - 24px);
  max-width: 724px;
  padding: 20px;
  overflow: scroll !important;

  &.modal-screencast-slider {
    min-height: auto;
  }

  .modal-close {
    font-size: 20px;
    right: 15px;
    top: 15px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-white);
    border-radius: 100%;

    svg {
      font-size: 25px;
      color: var(--color-grey);
      transform: rotate(45deg);
    }
  }

  &.modal-bg {
    background-color: var(--color-white);
    min-height: 100px;
    max-height: calc(100% - 64px);
    height: auto;
  }

  .modal-body {
    margin-top: 20px;
    padding: 0; //latest changes
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: red;
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: rgb(47, 255, 0);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2);
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2);
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2);
  }
  .confirmation-text {
    background: rgba(140, 199, 20, 0.2);
    border: 1px solid var(--color-green);
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }

    .project-name {
      margin-top: 5px;
    }

    .confirmation-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: block;
    }

    .confirmation-sub-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      b {
        font-weight: 500 !important;
      }
    }
  }
  textarea {
    border: 1px solid var(--color-grey);
    border-radius: 10px;
    padding: 15px;
    min-height: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
  }
  .modal-footer {
    margin-top: 30px;
    padding: 0; //latest changes
    border: none; //latest changes
    .feedback-footer {
      text-align: center;

      button {
        width: 160px;
      }
    }

    .btn {
      max-width: 100%;
    }
  }
  // .modal-footer2 {
  //   margin-top: 15px;
  //   border-top: none;
  //   .feedback-footer2 {
  //     text-align: center;

  //     button {
  //       width: 160px;
  //     }
  //   }

  //   .btn {
  //     max-width: 100%;
  //   }
  // }
}

.select-dot-option {
  .css-8id985-singleValue {
    padding-left: 14px;
    &::before {
      position: absolute;
      left: 8px;
    }
  }
}

.not-found-container {
  max-width: 480;
  margin: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 64px;

  .content {
    color: var(--color-grey);
  }

  img {
    height: 260px;
    margin: 80px auto;
    // my: { xs: 5, sm: 10 }
  }

  button {
    background-color: var(--color-green);

    &:hover {
      background-color: var(--color-green);
    }
  }
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__toast {
      background-color: var(--color-black);
      color: var(--color-white);
    }
    .Toastify__toast-body {
      .Toastify__toast-icon {
        svg {
          fill: var(--color-green);
        }
      }
    }
    .Toastify__close-button {
      svg {
        color: var(--color-white);
      }
    }
    .Toastify__progress-bar {
      background-color: var(--color-green);
    }
  }
}

.add-timesheet-datepicker {
  border: 1px solid var(--color-light-grey) !important;
  border-radius: 10px;
  min-height: 50px;
  display: flex;

  svg {
    margin-top: 10px;
  }
  &:hover {
    border: 1px solid var(--color-green);
  }

  .rs-picker-toggle {
    z-index: 0 !important;
  }
}
.add-release-note-datepicker {
  border: 1px solid var(--color-light-grey) !important;
  border-radius: 10px;
  min-height: 50px;
  display: flex;

  svg {
    margin-top: 6px;
  }
  &:hover {
    border: 1px solid var(--color-green);
  }
}
.error-datepicker {
  border: 1px solid red !important;
  border-radius: 10px;
  min-height: 50px;
  display: flex;

  svg {
    margin-top: 5px;
  }
  &:hover {
    border: 1px solid var(--color-green);
  }
}
.rs-stack-item {
  display: flex !important;
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-picker-daterange-calendar-group .rs-calendar:last-child {
  display: none;
}

.rs-picker-daterange-calendar-group {
  min-width: auto;
}

.rs-picker-menu {
  z-index: 9999 !important;
  @media(max-width:340.98px){
    left:50px !important
  }
}
.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-menu .rs-picker-toolbar-ranges {
  flex-direction: column;
}

.rs-picker-toggle {
  border: 1px solid var(--color-grey);
  border-radius: 10px !important;
}
.rs-picker-toggle:hover {
  border-color: var(--color-green) !important;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
  border-color: var(--color-green) !important;

  &:hover {
    border-color: var(--color-green) !important;
  }
}

.rs-btn-icon {
  background: var(--color-green);
  border: 1px solid var(--color-green);
  border-radius: 5px;
  padding: 0;
}

.rs-btn-icon svg {
  color: var(--color-white);
  font-size: 20px !important;

  &:hover {
    fill: var(--color-green) !important;
  }
}

.rs-calendar-header-title {
  margin: 0 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
}

.rs-calendar-table-header-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content {
  background: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--color-green);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color-green) !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell:hover {
  background-color: transparent !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-in-range::before {
  background: rgba(140, 199, 20, 0.3);
  border-top: 1px solid var(--color-green);
  border-bottom: 1px solid var(--color-green);
}

.rs-stack-item {
  width: 100%;
  text-align: center;
}

.rs-stack-item .rs-btn-link {
  width: 171px;
  text-align: center;
  border: 1px solid var(--color-green);
  border-radius: 5px;
  color: var(--color-black) !important;
  text-decoration: none !important;
}

.rs-stack-item .rs-btn-link:hover {
  background: rgba(140, 199, 20, 0.3);
}

.rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
  width: 100%;
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  top: 5px;
}

.rs-picker-toolbar-right {
  width: 100%;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-year-active {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
}

.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-toolbar .rs-stack-item {
  max-width: 171px;
}

.rs-picker-toggle-read-only {
  opacity: 1;
}

.chart-container {
  position: relative;

  &:hover {
    .google-visualization-tooltip {
      background-color: #fff;
      border: 1px solid #777;
      position: absolute;
      z-index: 1;
      border-radius: 10px;
      white-space: nowrap;
      overflow: hidden;

      // display: none;

      // p:nth-child(3){
      //   display: none;
      // }

      // p {
      //   display: none;
      // }

      // span.duration-text {
      // }

      // span:nth-child(2){
      //   display: none;
      // }

      // div:first-child(){
      //   display: none;
      // }

      div ~ * {
        display: none; // Hide all elements after the first child div
      }
    }
  }
}

// account dropdown menu
.download-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    width: 296px;

    .divider-border {
      background: var(--color-light-green);
      margin: 6px 0px;
    }

    .links {
      gap: 0px;
      display: flex;
      flex-direction: column;
      .MuiMenuItem-root {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
      }
      .menu-link {
        // gap: 14px;
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        color: var(--color-grey);
        text-decoration: none;
        transition: 0.3s all;

        &:hover,
        &:focus {
          background-color: transparent;
          color: var(--color-green);
        }
        .title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 4px;
        }
        .link-icon {
          margin-left: 6px;
          min-width: 22px;
        }
      }
    }
  }
}

.form-control:focus {
  background-color: #fff;
  border-color: var(--color-green);
  outline: 0;
  box-shadow: none;
}
.select__control--menu-is-open,
.css-hsmgan-control {
  box-shadow: none !important;
}

// MANKING SIDEBAR RESPONSIVE IN MOBILE VIEW

.header {
  @media (max-width: 991.98px) {
    margin-left: 0 !important;
    width: 100% !important;
  }
}
.nav-icons{
    display: flex;
    gap: 16px;
    align-items: center;
@media(max-width:378.98px ){
     margin-right: 45px;
}
}
.navbar-right {
  // margin: 0 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 991.98px) {
    // margin-right: 16px;
  }
}
.navbar-right {
  // padding-left: 45px;
  width: 100%;
  @media (max-width: 991.98px) {
    // padding-left: 26px;
  }
}
.page-wrapper {
  @media (max-width: 991.98px) {
    margin-left: 0 !important;
  }
}

.sidebar {
  .MuiDrawer-paper {
    .site-logo {
      @media (max-width: 991.98px) {
        width: 300px !important;
        background-color: #323232 !important;
        img {
          margin: unset !important;
          margin-right: auto !important;
          margin-left: 10px !important;
        }
      }
    }
    .sidebar-menu {
      @media (max-width: 991.98px) {
        width: 300px !important;
        background-color: #323232 !important;
      }
    }
    .MuiDivider-fullWidth {
      @media (max-width: 991.98px) {
        width: 300px !important;
        background-color: #323232 !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    .MuiModal-backdrop {
      display: none !important;
    }
  }
}
.MuiTablePagination-toolbar{
  padding: 0 !important;
}
.MuiTablePagination-root{
  .MuiInputBase-root{
    &.MuiInputBase-colorPrimary{
      @media(max-width:425.98px){
        margin-right: 8px;
      }
    }
  }
  .MuiTablePagination-actions{
    @media(max-width:425.98px){
      margin-left: 10px !important;
      button{
        padding: 0;
      }
    }
  }
}
.MuiMenu-list{
  .MuiTablePagination-menuItem{
    &.Mui-selected, &:hover{
      background-color: #8ec53433 !important;
    }
  }
}
.p-0{
  padding: 0;
}

.pagination-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 16px;
  .rows-per-page-wrapper{
    gap: 12px;
    display: flex;
    align-items: center;
    p{
      margin-top: 4px;
      white-space: nowrap;
    }
    .MuiFormControl-root{
      margin-top: 0;
      margin-bottom: 0;
      border-color: #777 !important;
      box-shadow: none !important;
      .MuiInputBase-input{
        padding-left: 10px;
        padding-right: 20px;
      }
      svg{
        right: 0;
        top: 12px !important;
        padding-top: 0 !important;
      }
      fieldset{
        border-color: #777 !important;
      }
      &.Mui-Focused{
        fieldset{
          border-color: transparent !important;
        }
      }
    }
  }
  .MuiPaginationItem-root{
    border-radius: 8px !important;
  }
}

.no-scroll {
    overflow: hidden !important;
}
