.page-wrapper {
    .load-more-section{
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 10px;

        button{
            width: max-content !important;
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .top-bar{
        .release-top-wrapper{
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    .card {
        margin-bottom: 20px;
    }
    .release-note{
        // padding: 10px;

        .heading{
            display: flex;
            flex-direction: row;
            gap: 28px;
            padding: 16px;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            @media(max-width:768.98px){
                gap: 20px;
            }
            @media(max-width:460.98px){
                align-items: flex-start;
            }

            .release-action-container{
                align-items: center;
            }

            .version-name {
                font-size: 22px;
                color: var(--color-black);
                font-weight: 500;
                line-height: normal;
                @media(max-width:768.98px){
                    font-size: 18px;
                }
            }
            
            .date {
                font-size: 18px;
                color: var(--color-grey);
                font-weight: 500;
                line-height: normal;
                font-style: italic;
                @media(max-width:768.98px){
                    font-size: 16px;
                }
            }
            .down-arrow {
                font-size: 32px;
                color: var(--color-grey);
                cursor: pointer;
            }
            .rotate-transition {
                transition: transform 0.3s ease; /* Adjust the duration as needed */
            }
        }

        .content{
            background-color: #f9f9f9 !important;
        }
        .MuiCollapse-wrapper{
            padding: 0 16px 16px 16px;
        }
    }

    .add-release{
        .quill-box{
            .ql-toolbar{
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }
            .ql-container{
                min-height: 250px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
        .error-quill-box{
            .ql-toolbar{
                border: 1px solid red;
                border-bottom: 1px solid #ccc;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }
            .ql-container{
                border: 1px solid red;
                border-top: 1px solid #ccc;
                min-height: 250px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
    }
}
.release-note-title{
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width:600.98px){
        gap: 16px;
    }
    @media(max-width:460.98px){
        flex-direction: column;
        align-items: self-start;
        gap: 0;
    }
}