.page-wrapper {
  .only-icon-btn {
    max-width: 50px;
    height: 40px;
    min-width: 50px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 0 !important;
    }
  }

  .myDate {
    .MuiInputBase-root {
      padding-top: 4px;
      max-height: 58px;
      max-width: 168px;
    }
    fieldset {
      border-color: #ccc !important;
      border-radius: 10px;
      background-color: #fff;
      color: #777 !important;
      z-index: -1;
    }
    &:hover,
    &:focus,
    &:active {
      fieldset {
        border-color: var(--color-green) !important;
      }
    }
  }

  .screenshot-wrapper {
    .slider-title {
      background-color: var(--color-black);
      min-height: 45px;
      border-radius: 10px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1400px) {
        .select-all-ss {
          margin-right: 70px !important;
        }
      }
      .card-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        @media (max-width: 768.98px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 70%;
        }
        @media (max-width: 390.98px) {
          font-size: 16px;
        }
        @media (max-width: 340.98px) {
          font-size: 14px;
        }
      }
    }

    .screencast-slider {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .project-title {
        display: block;
      }

      .slick-slider {
        .slick-arrow {
          top: -48px;

          &.slick-next {
            right: 15px;
          }

          &.slick-prev {
            right: 55px;
          }
        }
      }
    }
  }
}

.filter-drawer {
  .MuiDrawer-paper {
    max-width: 350px;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    .close-filter {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 24px;
      transform: rotate(45deg);
      cursor: pointer;
      z-index: 5;
    }

    .filter-tab {
      border-bottom: 1px solid #cccccc;

      .filter-btn {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 38px;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: capitalize;
        color: var(--color-grey);
        min-width: 50px;
      }

      .MuiTabs-indicator {
        background-color: var(--color-green);
      }
    }

    .filter-content {
      margin-top: 20px;

      .forgot-password {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: var(--color-black);
        text-align: right;
        cursor: pointer;
      }

      .btn {
        max-width: 100%;
        margin-top: 10px;
      }
      .checkbox-field {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      .select-filter {
        .css-reqq1a-control,
        .css-17cqwks-control {
          min-height: 50px;
        }
      }
    }
  }
}
