.loader-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loader-container p{
    color: #eee;
    font-size: 16px;
}

.loader-logo{
    background-color: var(--color-black);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 2px solid var(--color-green);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin: 0 auto;
    margin-bottom: 25px;

    @media(max-width:1560.98px){
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
    }
    img{
        width: 100%;
    }
}

  .zoom-in-out-box {
    margin: 24px;
    // width: 50px;
    // height: 50px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0);
    }
  }
  