.page-wrapper {
  .mb-24 {
    margin-bottom: 24px;
  }

  // top bar
  .top-bar {
    margin-bottom: 30px;

    .top-action-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;

      .top-filter-field {
        flex-wrap: nowrap;
        .top-select-field {
          display: flex;
          flex-wrap: nowrap;
          .checkbox-field {
            span {
              white-space: nowrap;
              @media (max-width: 1366.98px) {
                font-size: 14px;
              }
            }
          }
        }
        .select-peojects {
          @media (max-width: 1366.98px) {
            min-width: 150px;
            white-space: nowrap;
            font-size: 14px;
          }
        }
      }

      .top-filter-btn {
        .to-do-items {
          flex-direction: row;
          flex-wrap: nowrap;
          display: flex;
          .btn {
            @media (max-width: 1366.98px) {
              font-size: 14px !important;
            }
            .searchbox {
              @media (max-width: 1366.98px) {
                width: 170px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .top-emp-content-flex {
      display: flex;
      flex-wrap: nowrap;
      button {
        white-space: nowrap;
      }
    }
    .MuiGrid-container {
      align-items: center;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
    }

    .select-day {
      width: 150px;
      margin-left: auto;
      // overflow: hidden;
      // border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      .css-1im77uy-control {
        border-radius: 10px;
      }
    }
  }

  // activity card with icon
  .activity-card-wrapper {
    .activity-card {
      background: var(--color-black);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        @media (max-width: 1280.98px) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        margin-top: 10px;
        display: block;
        line-height: 50px;

        @media (max-width: 1280.98px) {
          font-size: 35px;
          line-height: 40px;
        }
      }

      svg {
        color: var(--color-white);
        width: 70px;
        height: 70px;
        color: var(--color-green);
        stroke-width: 1.5;

        @media (max-width: 1280.98px) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .dashboard-widget-title {
    color: #323232;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
  }

  // card
  .card {
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    height: auto;

    .card-title-bar {
      margin-bottom: 15px;

      .card-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }

      .see-all {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        cursor: pointer;
      }
    }

    .employee-card-title-bar {
      margin-top: 15px;
      margin-bottom: 15px !important;
      background-color: var(--color-black);
      margin-left: 5px;
      width: 99.3%;
      height: 50px;
      // padding: 10px 10px 10px 15px;
      padding-left: 15px;
      border-radius: 5px;

      .MuiGrid-item {
        padding: 0px;
      }

      .card-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #fff !important;
      }

      .see-all {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #fff !important;
        cursor: pointer;
      }

      .MuiAvatar-root {
        background-color: var(--color-green);
        width: 30px;
        height: 30px;
        font-size: 14px;
      }
    }

    .widget-listing {
      .tab-area {
        margin: 0;
        margin-bottom: -5px;
        button {
          span.user-name {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-black);
          }
        }
        button.Mui-selected {
          span.role-name {
            color: var(--color-black);
          }
        }
        .MuiTabs-indicator {
          background-color: var(--color-green);
          bottom: 4px;
          height: 3px;
          border-radius: 12px;
        }
      }
      .user-listing-tables {
        > .MuiBox-root {
          padding-top: 12px !important;
          p.MuiTypography-root {
            @media (max-width: 991px) {
              overflow-x: auto;
            }
          }
        }
      }
    }
    // Table Styles//
    Table.widget-listing-table {
      thead {
        tr {
          th {
            font-weight: 700;
            font-size: 16px;
          }
          th.th-role-name {
            width: 80%;
          }
          th.th-action {
            width: 20%;
          }
        }
      }
      .action-btn-wrap {
        display: flex;
        gap: 16px;
      }
      tbody {
        position: relative;
        .table-no-data {
          width: 100%;
          display: block;
          text-align: center;
          padding: 40px 0;
          position: absolute;
          background-color: var(--white);
          height: 450px;
          margin-top: -6px;
        }
      }
    }
    .widget-listing-table {
      .MuiTableCell-root {
        @media (max-width: 767px) {
          padding: 8px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }

  // card
  .widget-card-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;

    .widget-card {
      background-color: var(--color-white);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      // padding: 15px;
      overflow-x: scroll;
      height: 100%;
      // height: auto;

      .card-title-bar {
        margin-bottom: 15px;

        .card-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
        }

        .see-all {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          cursor: pointer;
        }
      }

      .employee-card-title-bar {
        margin-top: 15px;
        margin-bottom: 15px !important;
        background-color: var(--color-black);
        margin-left: 5px;
        width: 99.3%;
        height: 50px;
        // padding: 10px 10px 10px 15px;
        padding-left: 15px;
        border-radius: 5px;

        .MuiGrid-item {
          padding: 0px;
        }

        .card-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #fff !important;
        }

        .see-all {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #fff !important;
          cursor: pointer;
        }

        .MuiAvatar-root {
          background-color: var(--color-green);
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
      }

      .widget-listing {
        .tab-area {
          margin: 0;
          margin-bottom: -5px;
          button {
            span.user-name {
              text-transform: capitalize;
              font-size: 16px;
              font-weight: 600;
              color: var(--color-black);
            }
          }
          button.Mui-selected {
            span.role-name {
              color: var(--color-black);
            }
          }
          .MuiTabs-indicator {
            background-color: var(--color-green);
            bottom: 4px;
            height: 3px;
            border-radius: 12px;
          }
          .MuiTabs-scrollButtons.Mui-disabled {
            opacity: 0.2;
          }
        }
        .user-listing-tables {
          > .MuiBox-root {
            padding-top: 12px !important;
            p.MuiTypography-root {
              @media (max-width: 991px) {
                overflow-x: auto;
              }
            }
          }
        }
      }
      // Table Styles//
      Table.widget-listing-table {
        thead {
          tr {
            th {
              font-weight: 700;
              font-size: 16px;
            }
            th.th-role-name {
              width: 80%;
            }
            th.th-action {
              width: 20%;
            }
          }
        }
        .action-btn-wrap {
          display: flex;
          gap: 16px;
        }
        tbody {
          position: relative;
          .table-no-data {
            width: 100%;
            display: block;
            text-align: center;
            padding: 40px 0;
            position: absolute;
            background-color: var(--white);
            height: 450px;
            margin-top: -6px;
          }
        }
      }
      .widget-listing-table {
        .MuiTableCell-root {
          @media (max-width: 767px) {
            padding: 8px;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }

    Table.widget-listing-table-manager {
      thead {
        tr {
          th {
            font-weight: 700;
            font-size: 16px;
          }
          &:nth-child(1) {
            min-width: 380px;
          }
          &:nth-child(2) {
            min-width: 80px;
          }
          &:nth-child(3) {
            min-width: 120px;
          }
        }
      }
      .action-btn-wrap {
        display: flex;
        gap: 16px;
      }
      tbody {
        position: relative;
        .table-no-data {
          width: 100%;
          display: block;
          text-align: center;
          padding: 40px 0;
          position: absolute;
          background-color: var(--white);
          height: 450px;
          margin-top: -6px;
        }
      }
    }
    .widget-listing-table-manager {
      .MuiTableCell-root {
        @media (max-width: 767px) {
          padding: 8px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }

  // slider
  .screencast-slider {
    .see-all {
      margin-right: 90px;
    }
    .employee-see-all {
      margin-right: 100px;
    }
    .see-all-right {
      margin-right: 10px;
    }

    .slick-slider {
      margin-top: 25px;
      padding-right: 10px;
      padding-left: 5px;

      .slick-track {
        display: flex;
        margin: 0px;
      }

      .slick-slide {
        margin: 0 5px;
      }

      .slick-list {
        margin: 0 -5px;
        padding-top: 18px;
      }

      .slick-arrow {
        width: 30px;
        height: 30px;
        background-color: var(--color-green);
        color: var(--color-white);
        border-radius: 5px;
        padding: 5px;
        &.slick-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &.slick-next {
          right: 1px;
          top: -39px;
        }

        &.slick-prev {
          left: unset;
          right: 42px;
          top: -39px;
        }
      }

      .project-title {
        width: 100%;
        word-wrap: normal;
        // background: rgba(140, 199, 20, 0.5);
        background: #f9f9f9;
        // display: none;
        display: block;

        border-radius: 0px 0px 10px 10px;
        text-align: center;
        padding-top: 8px;
        padding: 8px 6px 6px 6px;
        margin-top: -5px;

        p {
          margin-bottom: 0px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          align-items: center;
        }
        .project {
          font-size: 14px;
        }
        .time {
          font-size: 12px;
        }
        .activity {
          font-size: 12px;
        }

        span {
          padding: 0px;
        }
      }

      .screen-count {
        border-radius: 10px;
        background: #f0f0f0;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        // padding: 8px 18px;
        width: 139px;
        height: 36px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        color: #323232;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px; /* 100% */

        position: absolute;
        z-index: 9;
        width: 60%;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .screencast-card {
        position: relative;

        &:hover {
          .screencast-overlay {
            display: flex;
            flex-direction: column;
            gap: 0px;
          }
        }

        .screencast-img {
          width: 100%;
          min-height: 140px;
          height: 140px;
          object-fit: cover;
          border: 1px solid rgba(140, 199, 20, 0.5);
          border-radius: 10px;

          @media (min-width: 1920.98px) {
            height: 170px;
          }
        }
        .screencast-img-blur {
          filter: blur(3px);
        }

        .screencast-overlay {
          background: rgba(140, 199, 20, 0.4);
          border-radius: 10px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          display: none;
          z-index: 5;

          .screencast-icon-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 4px;
          }

          .screencast-view {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background-color: var(--color-white);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            cursor: pointer;

            svg {
              color: var(--color-green);
            }
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 0;
            text-align: center;
            margin-top: 14px;
          }
        }
      }

      .time-slot {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;

        border-radius: 0px 0px 10px 10px;
        background: #f9f9f9;
        padding-top: 8px;
        // padding: 8px 6px 6px 6px;
        padding: 8px 2px 0px 2px;
        margin-top: -5px;

        .checkbox-field {
          margin: 0px;
        }

        .time-slot-checkbox {
          width: 5px !important;
          height: 5px !important;
          color: var(--color-grey);

          &.Mui-checked {
            color: var(--color-green);
          }
        }

        p {
          margin: 0px;
          font-size: 10px;
        }
        @media (min-width: 1024px) {
          p {
            font-size: 11px;
          }
        }
        @media (min-width: 1280px) {
          p {
            font-size: 12px;
          }
        }
        span {
          padding: 0px;
        }
      }

      .billable-slot {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        border-radius: 0px 0px 10px 10px;
        background: #f9f9f9;
        padding-top: 8px;
        // padding: 8px 6px 6px 6px;
        padding: 8px 2px 0px 2px;
        margin-top: -5px;

        .checkbox-field {
          margin: 0px;
          svg {
            width: 20px;
            height: 1em;
          }
          .MuiFormControlLabel-label {
            font-size: 14px;
            margin-left: 14px;
          }
        }

        .billable-slot-checkbox {
          width: 5px !important;
          height: 5px !important;
          color: var(--color-grey);

          &.Mui-checked {
            color: var(--color-green);
          }
        }

        p {
          margin: 0px;
          font-size: 10px;
        }
        @media (min-width: 1024px) {
          p {
            font-size: 11px;
          }
        }
        @media (min-width: 1280px) {
          p {
            font-size: 12px;
          }
        }
        span {
          padding: 0px;
        }
      }
    }

    .employee-slick-slider {
      .slick-arrow {
        width: 30px;
        height: 30px;
        background-color: var(--color-green);
        color: var(--color-white);
        border-radius: 5px;
        padding: 5px;
        &.slick-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &.slick-next {
          right: 14px;
          top: -50px;
        }

        &.slick-prev {
          left: unset;
          right: 56px;
          top: -50px;
        }
      }
    }
    //
  }

  // table
  .table-wrapper {
    padding-bottom: 5px;
    .employee-summary-table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              min-width: 250px;
            }
            &:nth-child(2) {
              min-width: 80px;
            }
            &:nth-child(3) {
              min-width: 300px;
            }
            &:nth-child(4) {
              min-width: 120px;
            }
            &:nth-child(5) {
              min-width: 120px;
            }
          }
        }
      }
    }
    .todo-summary-table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              min-width: 90px;
            }
            &:nth-child(2) {
              min-width: 200px;
            }
            &:nth-child(3) {
              min-width: 150px;
            }
          }
        }
      }
    }
    .project-summary-table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              min-width: 90px;
            }
            &:nth-child(2) {
              min-width: 200px;
            }
            &:nth-child(3) {
              min-width: 150px;
            }
          }
        }
      }
    }
    table {
      thead {
        background-color: var(--color-black);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        tr {
          th {
            color: var(--color-white);
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            padding: 10px 20px;

            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-weight: 400;
            font-size: 15px;
            line-height: 27px;
            color: var(--color-grey);
            padding: 10px 20px;
            border-bottom: 1px solid rgba(119, 119, 119, 0.2);

            .MuiAvatar-root {
              background-color: var(--color-green);
              width: 30px;
              height: 30px;
              font-size: 14px;
            }
          }
          .employee-name-avatar {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-green);
      border-radius: 50px;
    }
  }

  // activity chart
  .chart-wrapper {
    img {
      height: 386px;
      object-fit: fill;
    }
  }

  // top-worked-project
  .top-worked-project {
    overflow: auto;

    .top-project-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 31px;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      .worked-progress-bar {
        width: 200px;

        span {
          background-color: #e3e3e3;
          border-radius: 5px;

          span {
            background-color: var(--color-green);
          }
        }
      }

      .duration {
        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          text-align: right;
          margin-bottom: 0;
          width: 100px;
        }
      }
    }
  }
}
.project-name {
  display: flex;
  align-items: center;
  min-width: 250px;
  // max-width: 300px;

  .initial {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    border-radius: 30px;
    width: 30px;
    height: 30px;
    background-color: var(--color-green);

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
      white-space: nowrap;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    margin-left: 10px;
    white-space: nowrap;
  }
}

.modal-screencast-slider {
  // max-width: 1024px;
  max-width: 70%;
  // height: 800px;
  margin: 0 auto;
  text-align: center;
  overflow: auto;
  background-color: transparent;

  img {
    width: 100%;
    // max-width: 1024px;
    margin-bottom: 5px;
  }
  .screen-name {
    // padding: 5px;
    // margin-top: 4px;
    // border: 2px solid white;
    width: max-content;
    margin: 0px auto;
    // background-color: rgba(0,0,0,0.5);
    color: #fff;
  }

  .image-wrapper {
    position: relative;

    .download-screencast {
      width: 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 100%;
      background-color: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      right: 50px;
      position: absolute;
      svg {
        color: var(--color-green);
        font-size: 18px;
      }
    }
  }

  .modal-close {
    right: 30px;
    top: 30px;
    z-index: 100;
  }
  .modal-image-check {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    // top: 530px;
    bottom: 10px;
    background-color: white;
    border-radius: 50%;
    z-index: 100;
    width: 33px;
    height: 33px;
    // border: 2px solid red;
    box-shadow: 0px 0px 5px 5px #dfdfdf3a;
  }
  .slick-arrow {
    width: 30px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--color-white);
    // padding: 5px;

    &.slick-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.slick-next {
      right: 0px;
      top: 50%;
      display: none !important;
      // display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px 0px 0px 5px;
      // background-color: #141312;
      // border-radius: 8px;
      // filter: alpha(opacity=60);
      // opacity: 0.60;
    }

    &.slick-prev {
      left: 0px;
      top: 50%;
      z-index: 10;
      display: none !important;
      // display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px 5px 5px 0px;
      // background-color: #141312;
      // border-radius: 8px;
      // filter: alpha(opacity=60);
      // opacity: 0.60;
    }
  }

  .custom-slider-buttons {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 5;
  }

  .custom-prev-button {
    position: absolute;
    top: -360px;
    left: 0;
    border-radius: 0px 5px 5px 0px;
    justify-content: center;
    align-items: center;
  }

  .custom-next-button {
    position: absolute;
    top: -360px;
    right: 0;
    border-radius: 5px 0px 0px 5px;
    justify-content: center;
    align-items: center;
  }

  .custom-prev-button,
  .custom-next-button {
    width: 35px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--color-white);
    display: flex;
  }

  .custom-prev-button svg,
  .custom-next-button svg {
    background-color: #fff;
    border-radius: 50%;
    color: black;
    height: 20px;
    width: 20px;
    font-weight: bold !important;
  }

  .custom-prev-button svg:hover,
  .custom-next-button svg:hover {
    opacity: 1;
  }

  @media (max-width: 1920px) {
    .custom-prev-button,
    .custom-next-button {
      top: -440px;
    }
  }

  @media (max-width: 1820px) {
    .custom-prev-button,
    .custom-next-button {
      top: -420px;
    }
  }

  @media (max-width: 1690px) {
    .custom-prev-button,
    .custom-next-button {
      top: -390px;
    }
  }

  @media (max-width: 1560px) {
    .custom-prev-button,
    .custom-next-button {
      top: -360px;
    }
  }

  @media (max-width: 1440px) {
    .custom-prev-button,
    .custom-next-button {
      top: -340px;
    }
  }

  @media (max-width: 1366px) {
    .custom-prev-button,
    .custom-next-button {
      top: -320px;
    }
  }

  @media (max-width: 1280px) {
    .custom-prev-button,
    .custom-next-button {
      top: -290px;
    }
  }

  @media (max-width: 1024px) {
    .custom-prev-button,
    .custom-next-button {
      top: -270px;
    }
  }
}

.content-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 14px;
  &.dashboard-header {
    align-items: center;
  }
  .content-header-right {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
  }
  .calendar-ui {
    @media (max-width: 576.98px) {
      width: 100%;
      .rs-picker-toggle-wrapper {
        width: 100%;
      }
    }
  }
  &.dashboard-header {
    .content-header-right {
      @media (max-width: 576.98px) {
        display: none;
      }
    }
  }
}
.rs-stack-item {
  @media (max-width: 520.98px) {
    flex-direction: column;
  }
  .rs-picker-toolbar .rs-stack-item {
    @media (max-width: 520.98px) {
      max-width: 100%;
    }
  }
  .rs-picker-toolbar {
    .rs-btn-link {
      @media (max-width: 576.98px) {
        max-width: 100px;
      }
      @media (max-width: 460.98px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
.input-label .asterisk {
  color: var(--color-red) !important;
  font-size: 18px;
  line-height: 15px;
}
.time-slot {
  p {
    width: 100%;
    text-align: center;
  }
}
.billable-text {
  .MuiFormControlLabel-label {
    position: relative;
    top: 0.5px;
  }
  .billable-slot-checkbox {
    margin-top: -0.05px;
  }
}
.split-checkbox {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

// ------------------------------------------------------------
.blurred {
  filter: blur(5px);
  // pointer-events: none; /* Disable interactions */
  position: relative;
}

.eye-icon-container {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: rgba(225, 254, 178, 0.393);
  border-radius: 10px;
}

.eye-icon {
  // background-color: white;
  // border-radius: 10px !important;
  // boxShadow: 'none',
  // padding: 10px;
  box-shadow: "none";
  &:hover {
    box-shadow: "none";
    background-color: transparent !important;
  }
}
.user-table-container {
  max-height: 917px;
  min-height: 917px;
  height: 100%;
  @media (max-width: 1199px) {
    min-height: auto;
  }
}
