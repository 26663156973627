.page-wrapper {
  .account-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media(max-width:900.98px){
      flex-direction: column;
    }
    .account-tab {
      max-width: 357px;
      width: 100%;
      margin-top: 114px;
      min-height: calc(100vh - 244px);
      padding: 20px;
      position: sticky;
      top: 214px;
      @media(max-width:900.98px){
        max-width: 100%;
        position: unset;
        margin-top: 100px;
        min-height: auto;
      }
      button{
        max-width: 100%;
      }

      .user-profile {
        width: 200px;
        height: 200px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 5px solid var(--color-green);
        background-color: var(--color-white);
        margin: -120px auto 0;
        margin-bottom: 40px;

        img {
          // width: 160px;
          // height: 160px;
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        
      }

      .my-account-tab {
        margin-bottom: 0;
        .MuiTabs-indicator {
          display: none;
        }

        .MuiTabs-flexContainer {
          flex-direction: column;
          gap: 16px;
        }

        .btn {
          border-radius: 10px;
          border: 1px solid var(--color-green);
          color: var(--color-green);
          box-shadow: none;

          &.Mui-selected,
          &:hover {
            color: var(--color-white);
            background-color: var(--color-green);
            box-shadow: none;
          }
        }
      }
      .btn-tertiary{
        max-width: 100%;
      }
    }

    .account-content {
      max-width: calc(100% - 381px);
      width: 100%;
      min-height: calc(100vh - 130px);
      padding: 20px;
      @media(max-width:900.98px){
        min-height: auto;
        max-width: 100% !important;
      }
      .form-field{
        margin-top: 24px;
        .select-time-zone{
          .css-reqq1a-control, .css-17cqwks-control{
            min-height: 50px !important;
            border-color: var(--color-black);
          }
        }
        .user-upload-image{
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 20px;
          .user-img{
            width: 54px;
            height: 54px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--color-green);
            background-color: var(--color-black);
            img{
              width: 40px;
              height: 40px;
            }
          }
          .upload-img-btn{
            width: 166px;
          }
        }
        .password-field{
          margin-bottom: 30px;
        }
      }
    }
  }
  .my-account-content{
   
    button{
      max-width: 100%;
    }
  }
}