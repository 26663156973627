// header
.header {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important;
  .toolbar {
    min-height: 65px;
    background: #fff;
    padding-left: 30px !important;
    padding-right: 30px !important;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991.98px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
    .page-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 45px;
      text-transform: capitalize;
      color: var(--color-black);
      font-family: "Poppins", sans-serif;

      @media (max-width: 1560.98px) {
        font-size: 25px;
        line-height: 40px;
      }
      @media (max-width: 768.98px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .drawer-open-icon {
      width: 30px;
      height: 30px;
      background-color: var(--color-green);
      border: 1px solid var(--color-green);
      padding: 0 0 0 2px;
      font-size: 25px;
      color: var(--color-white);
      margin-left: 0;
    }
    .account-dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: var(--color-black);
      // margin-left: 10px;

      @media (max-width: 768.98px) {
        width: 32px;
        height: 32px;
      }
      button {
        border: 2px solid var(--color-green);
        padding: 0;
        background-color: var(--color-black);
        .avatar {
          background-color: var(--color-black);
          width: 40px;
          height: 40px;
          @media (max-width: 768.98px) {
            width: 36px;
            height: 36px;
          }
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
    .app-button {
      display: flex;
      align-items: center;
      justify-content: center;

      color: black;
      margin-left: auto;

      cursor: pointer;
    }
    .release-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: var(--color-black);
      //  margin-left: 1rem;
      border: 2px solid var(--color-green);
      cursor: pointer;

      @media (max-width: 1560.98px) {
        min-width: 40px;
        width: 40px;
        height: 40px;
      }
      @media (max-width: 768.98px) {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }
      .release-icon {
        color: var(--color-green);
        @media (max-width: 768.98px) {
          font-size: 20px;
        }
      }
    }
  }
}

// account dropdown menu
.account-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    .user-details {
      .user-img {
        width: 70px;
        height: 70px;
        background-color: var(--color-black);
        // padding: 11px;
        border-radius: 100px;
        border: 2px solid var(--color-green);
        margin: 0 auto;
        margin-bottom: 5px;
        @media (max-width: 1560.98px) {
          width: 50px;
          height: 50px;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .user-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
      .user-position {
        text-align: center;
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .divider-border {
      background: var(--color-light-green);
      margin: 15px 0px;
    }
    .organization-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;
      .org-img {
        width: 30px;
        height: 30px;
        background-color: var(--color-black);
        padding: 2px;
        border-radius: 100px;
        border: 2px solid var(--color-green);
        margin-bottom: 5px;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .org-text {
        h5 {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          white-space: nowrap;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }
      }
    }
    .links {
      gap: 15px;
      display: flex;
      flex-direction: column;
      .menu-link {
        gap: 14px;
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        min-height: auto;
        &:hover,
        &:focus {
          background-color: transparent;
        }
        .link-icon {
          margin-left: 6px;
          min-width: 22px;
        }
      }
    }
  }
}

// Sidebar
.sidebar {
  .MuiDrawer-paper {
    background-color: #232323;
    .site-logo {
      min-height: 65px;
      position: relative;
      @media (max-width: 991.98.98px) {
        background-color: var(--color-black);
      }
      img {
        height: 37px;
        cursor: pointer;
        margin-right: auto;
        margin-left: 12px;
      }
      button {
        position: absolute;
        width: 30px;
        height: 30px;
        padding: 0 2px 0 0;
        font-size: 25px;
        color: var(--color-white);
      }
    }
    .sidebar-menu {
      padding-top: 20px;
      padding-bottom: 30px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      
      .first-list,
      .second-list {
        width: 100%;
        a {
          text-decoration: none;
        }
      }
      .second-list{
        padding: 0 20px;
      }

      .download-app {
        border: 2px solid var(--color-green);
        border-radius: 10px;
        height: 45px !important;
        padding-right: 10px;
        padding-left: 12px;
        position: relative;

        &:hover {
          background-color: var(--color-green) !important;
        }

        p {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          white-space: nowrap;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: var(--color-white);
          svg {
            width: 20px;
            height: 20px;
            transform: rotate(0deg) !important;
          }
        }
      }

      .menu-list {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        margin-bottom: 11px;
        &:before {
          display: none;
        }
        .menu-title {
          border-radius: 10px;
          min-height: 45px !important;
          max-height: 45px !important;
          padding: 0 20px;
          &.Mui-expanded,
          &:hover {
            // background-color: var(--color-green);
            p{
              color: var(--color-white);
            }
            svg{
              color: var(--color-green);
            }
          }
          svg {
            font-size: 20px;
            // color: #fff;
            color: #a5a5a5;
          }
          .Mui-expanded {
            svg {
              transform: rotate(-270deg);
            }
          }
          p {
            display: flex;
            align-items: center;
            gap: 10px;
            white-space: nowrap;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            // color: var(--color-white);
            color: #a5a5a5;
            svg {
              width: 25px;
              height: 25px;
              transform: rotate(0deg) !important;
            }
          }
        }
        .sub-menu-list {
          padding-bottom: 0;
          padding-left: 22px;
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .sub-menu-link {
            margin: 0;
            cursor: pointer;
            p {
              display: flex;
              align-items: center;
              gap: 18px;
              font-weight: 400;
              font-size: 16px;
              line-height: 27px;
              color: var(--color-white);
              margin: 0;
              svg {
                fill: var(--color-white);
              }
            }
            &.active {
              p {
                svg {
                  fill: var(--color-green);
                  color: var(--color-green);
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-wrapper {
  padding: 36px 30px 30px 30px !important;
  @media (max-width: 991.98px) {
    padding: 36px 16px 16px 16px !important;
  }
}

.MuiAccordionSummary-expandIconWrapper{
  svg{
    color: #a5a5a5 !important;
  }
}
.sidebar{

  .MuiAccordionSummary-expandIconWrapper{
    &:hover, &.Mui-expanded{
      svg{
        color: var(--color-white) !important; 
      }
    }
  }
}