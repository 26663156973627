.page-wrapper {
  .top-bar{
    .searchbox{
      border: 1px solid var(--color-grey);
      border-radius: 10px;
      padding: 7px 10px;
      input{
        padding: 0;
      }
      button{
        font-size: 16px;
      }
      &:focus{
        border: 1px solid var(--color-green);
      }
    }
  }

  td{
    &.Active,&.active{
      color: var(--color-green) !important;
      font-weight: 500 !important;
    }
    &.Archive,&.archive{
      color: var(--color-grey) !important;
      font-weight: 500 !important;
    }
  }

  .table-action-btn {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    svg {
        font-size: 24px;
    }

    button {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
    }
  }

  .project-table-listing {
    &:not(:last-child) {
        margin-bottom: 40px;
    }
    .delete-wrapper {
        background: #C5E389;
        border-radius: 5px;

        .btn {
            font-size: 16px !important;
            max-width: 160px;
            height: 40px;
        }
    }

    .table-wrapper {
      .project-table{        
        thead {
          tr {
            th {
              &:nth-child(1) {
                min-width: 50px;
              }
              &:nth-child(2) {
                min-width: 150px;
              }
              &:nth-child(5) {
                min-width: 120px;
              }
              &:last-child {
                text-align: center !important;
                min-width: 130px !important;
              }
            }
          }
        }
        tbody {
          tr {
            &.Mui-selected,
            &:hover {
                background-color: #c5e38980 !important;
            }
          }
          tr{
            td{
              .MuiAvatarGroup-root{
                justify-content: flex-end;
                cursor: pointer;
                
                .MuiAvatar-root{
                  background-color: var(--color-green);
                  width: 30px;
                  height: 30px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .other-project-table{        
        thead {
          tr {
            th {
              &:nth-child(1) {
                min-width: 50px;
              }
              &:nth-child(2) {
                min-width: 150px;
              }
              &:nth-child(5) {
                min-width: 120px;
              }
              &:nth-child(6) {
                min-width: 230px;
                width: 250px;
              }
              &:last-child {
                text-align: center !important;
                min-width: 70px !important;
              }
            }
          }
        }
        tbody {
          tr {
            &.Mui-selected,
            &:hover {
                background-color: #c5e38980 !important;
            }
          }
          tr{
            td{
              .MuiAvatarGroup-root{
                justify-content: flex-end;
                cursor: pointer;
                
                .MuiAvatar-root{
                  background-color: var(--color-green);
                  width: 30px;
                  height: 30px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// .mask-box-container{
//   display: flex;
//   .mask-box {
//     border-radius: 20px;
//     position: relative;
//     background: var(--color-grey);
//     display: flex;
//     padding: 3px;  
//     .mask {
//       width: 80px;
//       height: 30px;
//       font-size: 14px;
//       border-radius: 20px;
//       background: var(--color-green);
//       position: absolute;
//       transition: all 0.5s ease;
//     }
//     .MuiButton-root {
//       border-radius: 20px;
//       width: 80px;
//       height: 30px;
//       font-size: 14px;
//       font-weight: 400;
//       transition: all 0.2s 0.1s ease;
//       text-transform: capitalize;
//       color: var(--color-white);
  
//       &:hover {
//         opacity: 0.8;
//       }
//     }
//   }
// }
.td-accordion-wrapper{
  border-radius: 0 !important;
  .MuiAccordionSummary-root{
    max-height: 48px !important;
    min-height: 48px !important;
    margin: 0 !important;
    .Mui-expanded{
      margin: 0 !important;
    }
  }
  .MuiAccordionSummary-expandIconWrapper{
    transform: rotate(-90deg);
    &.Mui-expanded{
      transform: rotate(-180deg);
    }
  }
}
.project-module-wrapper{
  align-items: center;
  @media(max-width:1050.98px){
    flex-direction: column-reverse;
    align-items: baseline;
  }
  .content-header-right{
    @media(max-width:1050.98px){
      width: 100%;
    }   
    .searchbox{
      @media(max-width:1050.98px){
        width: calc(100% - 170px);
        .MuiInputBase-root{
          width: 100%;
        }
      }   
      @media(max-width:480.98px){
        width: 100%;
      }   
      .end-input-icon{
        button{
          @media(max-width:480.98px){
            width: fit-content !important;
            max-width: unset !important;
            // font-size: 24px;
          } 
        }
      }
    }
    button{
      @media(max-width:480.98px){
        width: 100% !important;
        max-width: 100% !important;
      }   
    }
  }
}