.page-wrapper {
  .report-top-bar {
    .select-project {
      z-index: 1015;
    }
  }
  .bnb-proj-report-wrapper {
    table {
      white-space: nowrap;

      .MuiTableCell-root {
        font-family: "Poppins";
      }

      thead {
        background-color: #8cc71480;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        tr {
          th {
            color: var(--color-black);
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            padding: 8px 20px;
            height: 48px !important;

            &:first-child {
              // min-width: 230px;
              width: 100px !important;
              // border: 2px solid blue;
            }

            &:last-child {
              min-width: 145 !important;
            }
            &:nth-last-child(2) {
              right: 67.5px !important;
            }
          }
        }
      }

      tbody {
        &:first-child {
          border-radius: 5px;
        }
        .member-name-BNB-report {
          .project-name {
            max-width: 210px !important;
            min-width: 210px !important;
          }
        }
        tr {
          td {
            text-align: center;
            border-radius: 0px !important;
            font-weight: 400;
            font-size: 15px;
            line-height: 27px;
            color: var(--color-grey);
            padding: 10px 15px;
            border-bottom: 1px solid rgba(119, 119, 119, 0.2);

            &.td-border-none {
              border: none;
            }
          }

          // &:first-child {
          //   background-color: var(--color-black);
          // }
        }

        .report-table-avatar {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .report-table-row {
          background-color: var(--color-black);
          color: var(--color-white);

          .report-table-date {
            color: var(--color-white);
            font-size: 15px;
          }
        }

        .project-table-row {
          background-color: var(--color-black);

          .project-table-avatar {
            color: var(--color-white);
            border-radius: 5px;
            font-size: 15px;
          }
        }
      }
    }

    .report-table-hidden {
      &:last-child {
        display: none;
      }
    }

    .project-total {
      font-weight: 500;
    }
  }
  .billable-report-table-wrapper {
    table {
      white-space: nowrap;
      border-collapse: separate !important;
      .MuiTableCell-root {
        font-family: "Poppins";
      }

      thead {
        background-color: #8cc71480;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        tr {
          th {
            color: var(--color-black);
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            padding: 8px 20px;
            height: 48px !important;

            &:first-child {
              // min-width: 230px;
              width: 100px !important;
              // border: 2px solid blue;
            }

            &:last-child {
              min-width: 145 !important;
            }
            &:nth-last-child(2) {
              right: 68.5px !important;
            }
          }
        }
      }

      tbody {
        &:first-child {
          border-radius: 5px;
        }
        .member-name-BNB-report {
          .project-name {
            max-width: 210px !important;
            min-width: 210px !important;
          }
        }
        tr {
          td {
            text-align: center;
            border-radius: 0px !important;
            font-weight: 400;
            font-size: 15px;
            line-height: 27px;
            color: var(--color-grey);
            padding: 10px 15px;
            border-bottom: 1px solid rgba(119, 119, 119, 0.2);

            &.td-border-none {
              border: none;
            }
          }

          // &:first-child {
          //   background-color: var(--color-black);
          // }
        }

        .report-table-avatar {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .report-table-row {
          background-color: var(--color-black);
          color: var(--color-white);

          .report-table-date {
            color: var(--color-white);
            font-size: 15px;
          }
        }

        .project-table-row {
          background-color: var(--color-black);

          .project-table-avatar {
            color: var(--color-white);
            border-radius: 5px;
            font-size: 15px;
          }
        }
      }
    }

    .report-table-hidden {
      &:last-child {
        display: none;
      }
    }

    .project-total {
      font-weight: 500;
    }
  }
}
.select-full-width {
  flex: 1 1 100% !important; /* The !important flag can be used in CSS files */
}
.content-header .select__menu {
  z-index: 9999 !important;
}
.item1 {
  margin-left: auto;
}
@media (max-width: 1024.98px) {
  .bnb-proj-report-wrapper {
    max-height: 100% !important;
    table {
      thead {
        tr {
          th {
            z-index: 1;
            &:first-child {
              left: unset;
            }
            &:last-child {
              right: unset;
            }
            &:nth-last-child(2) {
              position: inherit;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            left: unset !important;
            right: unset !important;
            z-index: 1;
            &:last-child {
              right: unset !important;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  .billable-report-table-wrapper {
    max-height: 100% !important;
    table {
      thead {
        tr {
          th {
            z-index: 1;
            &:first-child {
              left: unset;
            }
            &:last-child {
              right: unset;
            }
            &:nth-last-child(2) {
              position: inherit;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            left: unset !important;
            right: unset !important;
            z-index: 1;
            &:last-child {
              right: unset !important;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.accordion-row {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-row.open {
  max-height: 100px; /* Adjust this value based on your content's height */
}
