.side-drawer-2 {
    .MuiDrawer-paper {
      width: 100%;
      max-width: 450px;
      .sidebar-tab-wrapper-2 {
        .close-btn {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          cursor: pointer;
          position: absolute;
          right: 24px;
          top: 14px;
          z-index: 99;
  
          svg {
            font-size: 20px;
            path {
              color: var(--primary-text-color);
            }
          }
        }
  
        .sidebar-tab {
          border-bottom: 1px solid #cccccc;
          .tab-label-btn {
            opacity: 1;
            min-width: 120px;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
  
            &.Mui-selected {
              color: var(--primary-text-color);
            }
          }
  
          .MuiTabs-indicator {
            height: 3px;
            background-color: transparent;
            border-radius: 5px;
            left: 0;
            width: 120px;
          }
        }
      }
    }
  }
  .icon-black {
    fill: black !important;
  }

.select-date-range div{
  border-color: hsl(0, 0%, 80%) !important;
  // min-height: 38px !important;
}
// .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
//   color: black !important
// }
  .annoucement-modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
    // min-height: calc(100% - 64px);
    border-radius: 12px;
    width: calc(100% - 24px);
    max-width: 610px;
    width: 100%;
    padding: 20px;
    overflow: scroll !important;
  
    &.modal-screencast-slider {
      min-height: auto;
    }
  
    .modal-close {
      font-size: 20px;
      right: 15px;
      top: 15px;
      position: absolute;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: var(--color-white);
      border-radius: 100%;
  
      svg {
        font-size: 25px;
        color: var(--color-grey);
        transform: rotate(45deg);
      }
    }
    .ann-title h3{
      margin: 0;
      font-size: 18px !important;
      font-weight: 600 !important;
    }

    .ann-title p {
      color: #a5a5a5;
      font-size: 16px;
      margin: 8px 0 0;
    }
    .ann-footer{
      margin-top: 36px;
    }

    .ann-footer .ann-btn-group{
      align-items: center;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
}
  .modal-ui {
    padding: 0;
    .modal-body {
      padding: 24px;
    }
  }
  .modal-wrapper-announcement {
    // padding: 24px;
    max-width: 65vw;
    // width: 100%;
    // width: calc(100% - 32px);
    min-width: 650px;
    border-radius: 12px;
    background-color: white;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .ann-title {
        h3 {
            margin: 0;
        }
        p {
            color: var(--textgrey);
            font-size: 16px;
            margin: 8px 0 0 0;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
    .error {
        color: red !important;
        font-size: 14px !important;
    }
    .ann-footer {
        margin-top: 36px;
        .ann-btn-group {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
        }
    }
    .status-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fail-icon {
        align-self: center;
        color: red;
        font-size: 52px;
    }
    .success-icon {
        align-self: center;
        color: green;
        font-size: 52px;
    }
    .warning-icon {
        align-self: center;
        color: rgb(238, 255, 0);
        font-size: 52px;
    }
    .feedback-text {
        border-radius: 10px;
        padding: 20px;

        p {
            margin-bottom: 0;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-black);
        }
    }
    .fail-text {
        background: rgba(195, 90, 103, 0.2) !important;
    }
    .success-text {
        background: rgba(90, 195, 92, 0.2) !important;
    }
    .warning-text {
        background: rgba(183, 195, 90, 0.2) !important;
    }
    .confirmation-text {
        background: rgba(140, 199, 20, 0.2) !important;
        border: 1px solid var(--color-green);
        border-radius: 10px;
        padding: 20px;
    }
}
  .ann-label{
    color: var(--primary-text-color) !important;
    font-size: 13px !important;
    // margin-bottom: 4px !important;
    display: flex;
    font-weight: 700 !important;
  }
  .announcement-btn {
    border-radius: 10px !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    padding: 8px 16px !important;
    font-size: 0.875rem !important;
    letter-spacing: 0.02857em !important;
    min-width: 64px !important;
    font-weight: 500 !important;

  }
  .outline-btn {
    text-transform: capitalize !important;
    font-size: 14px !important;
    color: #232323 !important;
    background-color: transparent !important;
    border: 1px solid #232323 !important;
    &:hover {
      background-color: #f0f0f0 !important;
    }
  }

  .primary-btn{
    background-color: black !important;
    color: white !important;
  }
  .announcement-contents {
    margin: -14px 0px 21px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      margin: 0;
    }
  }

  @media(max-width:991.98px){
    .announcement-contents {
        margin: -17px 0px 21px 0px;
    }
  }

  .custom-icon-btn {
    width: 34.5px !important;
    height: 34.5px !important;
    min-width: unset !important;
    span {
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      font-size: 13px;
      margin: 0;
      svg {
        // fill: var(--white);
      }
    }
  }

  .filter-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
    .btn {
      width: 100px;
      height: 42px;
      box-shadow: none !important;
      font-size: 16px !important;
    }
  }
  .ann-error{
    color: #ee5e5e !important;
    font-size: 14px !important;
  }
  
  .secondary-btn {
    background-color: #f0f0f0 !important;
    color:#232323 !important;
    &:hover {
      background-color: #e0e0e0 !important;
    }
    
  }
  .announcement-title {
    cursor: default;
    padding-bottom: 8px;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #7cb900 !important;
  }
  .important-text{
    background-color: orange !important;
    color: black !important;
    font-weight: 500;
    height: 24px !important;
  
    padding: 4px !important;
    margin-top: 8px;
    span{
      font-size: 12px !important;
    }
  }

  
  .announcement-desc {
    display: flex;
    flex-direction: column;
    cursor: default;
    max-height: 420px;
    overflow: auto;
    // overflow-y: auto;
    // overflow-x: hidden;
    margin-top: 8px;
    padding: 10px;
    font-size: 15px !important;
    font-weight: 400 !important;
    p {
      font-size: 15px !important;
      color: black !important;
    }
  }
  .announcement-date {
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: nowrap !important;
  }
  
  .announcement-marque {
    width: 100%;
    background-color: rgba(124, 185, 0, 0.1);
    padding: 10px 16px;
    border-radius: 12px;
    // margin-bottom: 16px;
    overflow: hidden;
    border-color: rgb(124, 185, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: { xs: row; sm: row };
  
    .marque-list {
      display: inline-block;
      padding-left: 20px;
      display: flex;
      align-items: center;
    }
    .marque-text {
      font-weight: semibold;
      color: rgb(35, 35, 35) !important;
      cursor: pointer;
    }
  }
  .joiningdate {
    .rs-picker-toggle-wrapper {
      border-radius: 10px;
      input {
        border-radius: 10px;
      }
    }
  }
  .announcement-drawer {
    .MuiDrawer-paper {
      max-width: 540px !important;
    }
  }
  .drawer-container {
    .announcement-header {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
    }
    .tabs-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .icons {
      display: flex;
      align-items: center;
      padding-right: 5px;
    }
  }
  .announcement-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .announcement-section {
    // border: 2px solid red;
    padding: 10px;
    padding-right: 0;
  }
  .announcement-item {
    // border: 3px solid yellow;
    padding: 10px 10px;
    &:hover {
      background-color: rgba(234, 234, 234, 0.384);
      border-radius: 15px;
    }
    .announcement-content {
      width: 100%;
  
      .subject {
        font-size: 15px;
        padding-right: 15px;
      }
      .details {
        font-size: 14px;
        color: gray !important;
        padding-right: 15px;
      }
      .created-at {
        display: flex;
        justify-content: space-between;
        // border: 2px solid green;
        width: 100%;
        span {
          color: rgb(145, 145, 145);
        }
      }
    }
  }
  .announcement-drawer {
    .MuiDrawer-paper {
        max-width: 540px !important;
    }
}
.announcement-content-header{
  margin: 0px 0px 8px -24px;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
  @media (max-width: 991.98px) {
    margin: 0px 0px 8px 2px;
  }

}
.announcement-content-header h2{
  margin: 0;
  font-size: 24px !important;
  font-weight: 600 !important;

}
.action-btn span{
  margin: 0px;
}
.title-with-btn{
  display: flex;
  align-items: center;
  gap: 0px;
}
.title-with-btn button.back-btn svg{
  font-size: 30px;
}
.title-with-btn button{
  min-height: unset;
  height: auto;
}
.ann-content-header-right {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  .search-bar {
    position: relative;
    background-color: white;
    border-radius: 12px;
    @media (max-width: 575px) {
      width: 100%;
      margin-bottom: 8px;
    }
    svg {
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 18px;
      z-index: 2;
    }
    .cross-line {
      position: absolute;
      top: 10px;
      left: 90%;
      font-size: 18px;
      z-index: 2;
      cursor: pointer;
      fill: #b7b7b7 !important;
    }
    input {
      width: 230px;
      background-color: var(--white);
      padding-left: 32px !important;
      box-shadow: 0px 4px 4px 0px var(--secondary-text-color);
      border-radius: 10px !important;
      border-color: var(--textgrey) !important;
    }
    fieldset {
      border-radius: 10px !important;
      border: none !important;
    }
  }
}
.content-layout {
  border-radius: 12px;
  background-color: var(--white);
  margin: 0;
  @media (max-width: 991.98px) {
    margin: 24px 16px 24px 16px;
  }
  h3 {
    margin-top: 0px;
  }
}
.announcement-detail {
  cursor: pointer;
  .announcement-content-slider {
      cursor: pointer;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #7cb900 !important;

      // min-width: 400px;
      // max-width: 600px;
  }

  .announcement-title-slider {
      cursor: pointer;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #7cb900 !important;
  }

  .announcement-desc-slider {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-top: 8px;
      font-size: 14px !important;
      font-weight: 400 !important;
      p{
          color: black !important;
      }
       
  }
}


.modal-wrapper-announcement-slider {
   padding: 0 20px;
  max-width: 70vw;
  width: calc(100% - 32px);
  min-width: 50vw;
  border-radius: 12px;
  background-color: white;
  border: none;
  position: absolute;
  top: 50%;
  left: 30%;
  // transform: translate(-50%, -50%);
  overflow: hidden; /* Prevent overflow */
  
  /* Ensures no horizontal scroll */
  white-space: normal; /* Allow wrapping for long text */
  word-wrap: break-word; /* Break long words to avoid overflow */
  word-break: break-word;

  .announcement-slick{
    .slick-prev {
    // left: 3% !important;
    z-index: 1;
    top: 42% !important;
  }
  .slick-next {
    // right: 3% !important;
    z-index: 1;
    top: 42% !important;
  }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
    color: black !important
  }
    .slick-dots{
      position: relative !important;
      margin-bottom: 10px !important;
    }
  }

  .ann-title {
      h3 {
          margin: 0;
      }
      p {
          color: #a5a5a5;
          font-size: 16px;
          margin: 8px 0 0 0;
          @media (max-width: 767px) {
              font-size: 14px;
          }
      }
  }
  
  .error {
      color: red !important;
      font-size: 14px !important;
  }
  
  .ann-footer {
      margin-top: 36px;
      .ann-btn-group {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;
      }
  }

  .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .fail-icon, .success-icon, .warning-icon {
      align-self: center;
      font-size: 52px;
  }
  
  .fail-icon {
      color:red;
  }

  .success-icon {
      color: green;
  }

  .warning-icon {
      color: rgb(238, 255, 0);
  }
  
  .feedback-text {
      border-radius: 10px;
      padding: 20px;
      
      p {
          margin-bottom: 0;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--color-black);
      }
  }
  
  .fail-text {
      background: rgba(195, 90, 103, 0.2) !important;
  }

  .success-text {
      background: rgba(90, 195, 92, 0.2) !important;
  }

  .warning-text {
      background: rgba(183, 195, 90, 0.2) !important;
  }

  .confirmation-text {
      background: rgba(140, 199, 20, 0.2) !important;
      border: 1px solid green;
      border-radius: 10px;
      padding: 20px;
  }
}
.model-ui .modal-body{
  padding: 24px !important;
}

@media (max-width: 680px) {
  .modal-wrapper-announcement{
      min-width: 95vw;
      max-width: 95vw;
  }
}
@media (max-width: 900px) {
  .modal-wrapper-announcement-slider{
      min-width: 85vw;
      max-width: 85vw;
  }
}
// .slick-prev {
//   // left: 3% !important;
//   z-index: 1;
//   top: 42% !important;
// }
// .slick-next {
//   // right: 3% !important;
//   z-index: 1;
//   top: 42% !important;
// }
.announcement-card-wrapper {
  border-radius: 12px;
  background-color: var(--white);
  margin: 24px 0px 32px 0px;
  @media (max-width: 991.98px) {
    margin: 24px 0px 24px 0px;
  }
  h3 {
    margin-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .ann-back-btn {
    display: none !important;
  }
}

  .announcement-module-wrapper {
    flex-wrap: wrap-reverse;
    gap: 24px;
  }
  @media (max-width: 900.98px) {
    .announcement-module-wrapper .ann-content-header-right .search-bar input {
      width: 268px;
    }
  }
  @media (max-width: 900.98px) {
    .announcement-module-wrapper .ann-content-header-right {
      width: 100%;
    }
  }
  @media (max-width: 701px) {
    .announcement-module-wrapper .ann-content-header-right {
      width: 100%;
    }
  }
  @media (max-width: 900.98px) {
    .announcement-module-wrapper .ann-content-header-right .search-bar {
      flex: 1 1;
      width: 100%;
    }
  }
  @media (max-width: 900.98px) {
    .announcement-module-wrapper .ann-content-header-right .search-bar input {
      width: 100%;
    }
  }
  @media (max-width: 700.98px) {
    .announcement-module-wrapper .ann-content-header-right .primary-btn {
      max-width: 100%;
      width: 100%;
      // flex: 1 1;
    }
  }
  @media (max-width: 900.98px) {
    .announcement-module-wrapper {
      flex-direction: row;
    }
  }
  // .slick-dots{
  //   position: relative !important;
  //   margin-bottom: 10px !important;
  // }
  // @media (max-width: 768px) {
  //   .content-wrap {
  //     padding: 0 31px 0 24px !important;
  //   }
  // }