.page-wrapper {
  .select-date {
    // width: 320px;
    width: auto;
  }

  .top-bar {
    .btn {
      max-width: auto !important;
      height: 40px;
      font-style: normal;
      font-weight: 400;
      // font-size: 14px !important;
      line-height: 24px;

      svg {
        margin-right: 8px;
      }
    }

    .top-filter-btn {
      .member-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 10px;
      }

      .member-text {
        font-size: 16px;
      }

      .MuiAvatar-root {
        width: 30px;
        height: 30px;
        background: var(--color-green);
      }

      .timesheet-select-options {
        width: 180px;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        .css-reqq1a-control {
          border: 1px solid #ccc;
        }

        @media (max-width: 1163.98px) {
          min-width: 90px;
          white-space: nowrap;
          font-size: 14px;
        }
      }
    }
  }

  .table-action-btn {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    svg {
      font-size: 24px;
    }

    button {
      width: 40px;
      max-width: 40px;
      min-width: 40px;
      height: 40px;
    }
  }

  .timesheet-table-listing {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .delete-wrapper {
      background: #c5e389;
      border-radius: 5px;

      .btn {
        font-size: 16px !important;
        max-width: 160px;
        height: 40px;
      }
    }

    .expand-container {
      position: relative;

      .expand-button {
        font-size: 26px;
        cursor: pointer;
        color: var(--color-green);
        transition: transform 0.5s ease;
      }

      .rotate {
        transform: rotate(180deg);
      }
    }

    .table-wrapper {
      .view-edit-table {
        border-collapse: collapse !important;

        thead {
          tr {
            th {
              &:nth-child(1) {
                width: 50px !important;
                min-width: 50px !important;
                max-width: 50px !important;
              }

              &:nth-child(2) {
                min-width: 80px !important;
                max-width: 80px !important;
                width: 80px !important;
              }

              &:nth-child(3) {
                width: 180px !important;
                min-width: 180px !important;
                max-width: 190px !important;
              }

              &:nth-child(4) {
                width: 190px !important;
                min-width: 190px !important;
                max-width: 200px !important;
              }

              &:nth-child(5) {
                width: 290px !important;
                min-width: 250px !important;
                max-width: 340px !important;
              }

              &:nth-child(6) {
                width: 150px !important;
                min-width: 250px !important;
                max-width: 300px !important;
              }

              &:last-child {
                text-align: center;
                min-width: 100px !important;
                max-width: 100px !important;
              }
            }
          }
        }

        tbody {
          tr {
            &.Mui-selected,
            &:hover {
              background-color: #c5e38980 !important;
            }

            .inner-table-cell {
              // background-color: #f9f9f9;
              background-color: #fff;

              .inner-view-edit-table {
                border-collapse: collapse !important;

                thead {
                  background-color: rgba(50, 50, 50, 0.3) !important;

                  tr {
                    background-color: rgba(50, 50, 50, 0.3) !important;

                    th {
                      font-weight: bold;

                      &:nth-child(1) {
                        width: 345px !important;
                        min-width: 345px !important;
                        max-width: 370px !important;
                      }

                      &:nth-child(2) {
                        width: 190px !important;
                        min-width: 190px !important;
                        max-width: 200px !important;
                      }

                      &:nth-child(3) {
                        width: 190px !important;
                        min-width: 190px !important;
                        max-width: 210px !important;
                      }

                      &:nth-child(4) {
                        width: 95px !important;
                        min-width: 95px !important;
                        max-width: 120px !important;
                      }

                      &:nth-child(5) {
                        width: 140px !important;
                        min-width: 140px !important;
                        // max-width: 200px !important;
                      }

                      &:last-child {
                        text-align: center;
                        min-width: 150px !important;
                        max-width: 150px !important;
                      }
                    }
                  }
                }

                tbody {
                  background-color: #f9f9f9 !important;

                  .first-row {
                    td {
                      border-bottom-color: #f9f9f9;
                    }
                  }

                  .second-row {
                    td {
                      border-top-color: #f9f9f9;
                      padding: 3px 20px 3px 20px;
                    }
                    &:hover {
                      background-color: transparent !important;
                    }
                  }

                  tr {
                    // &.Mui-selected,
                    // &:hover {
                    //     background-color: #c5e38980 !important;
                    // }

                    td {
                      white-space: nowrap;
                      vertical-align: top;
                    }
                  }

                  .table-action-btn {
                    justify-content: center !important;
                  }
                }
              }
            }

            td {
              white-space: nowrap;
            }
          }

          .table-action-btn {
            justify-content: center;
          }
        }
      }
    }
  }

  .edit-time {
    .confirmation-text {
      background: rgba(140, 199, 20, 0.2);
      border: 1px solid var(--color-green);
      border-radius: 10px;
      padding: 20px;
      min-height: 90%;

      p {
        margin-bottom: 0;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-black);
      }

      .project-name {
        margin-top: 5px;
      }

      .confirmation-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: block;
      }

      .confirmation-sub-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        b {
          font-weight: 500 !important;
        }
      }
    }
  }
}

.modal-wrapper {
  .css-reqq1a-control,
  .css-17cqwks-control {
    min-height: 50px;
  }

  .form-control {
    margin-bottom: 0;
  }

  .add-note {
    color: var(--color-black);
    padding: 0;
    background-color: transparent !important;
    border: 0 !important;
    width: 130px;
    height: 30px;
  }

  .billable-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tooltip {
      svg {
        font-size: 20px;
      }
    }
  }

  .modal-body {
    .table-wrapper {
      max-height: 265px;
      position: relative;
      overflow: scroll;

      table {
        thead {
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: var(--color-black);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          tr {
            th {
              color: var(--color-white);
              font-weight: 500;
              font-size: 15px;
              line-height: 30px;
              padding: 10px 20px;

              &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-weight: 400;
              font-size: 15px;
              line-height: 27px;
              color: var(--color-grey);
              padding: 10px 20px;
              border-bottom: 1px solid rgba(119, 119, 119, 0.2);

              .MuiAvatar-root {
                background-color: var(--color-green);
                width: 30px;
                height: 30px;
                font-size: 14px;
              }
            }

            .employee-name-avatar {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }

    .work-task-card {
      background-color: #f4f1f1 !important;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 8px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .inprogress-textarea {
      textarea {
        min-height: 50px !important;
        font-size: 14px !important;
      }
    }

    .total-duration-box-container {
      background-color: var(--color-green);
      // border: 1px solid var(--color-green);
      height: 36px;
      width: 100px;
      display: flex;
      // padding: 0px 29px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      // @media(max-width:)

      p {
        margin: 0px;
        color: var(--color-white);
      }
    }
  }
}

.content-header {
  .content-header-right {
    &.top-filter-btn {
      button {
        width: fit-content;
        padding: 6px 8px;
        height: 38px;
        gap: 8px;
      }

      .form-control {
        width: 200px;
        border-radius: 12px;
        @media (max-width: 480.98px) {
          width: 100%;
        }
      }
    }
  }
}
.select__menu-list {
  .select__option {
    word-break: break-word;
    white-space: pre-wrap;
  }
}
.top-filter-field {
  gap: 16px;
}
.billable-dureation-wrapper {
  gap: 16px;
  display: flex;
  justify-content: space-between;
}
.add-new-todo-modal {
  margin-left: auto !important;
  background-color: transparent !important;
  color: var(--color-green) !important;
  padding: 0 !important;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}
.label-btn-wrapper {
  gap: 16px;
  justify-content: space-between;
}

.timesheets-header {
  .content-header-right {
    @media (max-width: 1150.98px) {
      width: 100%;
    }
  }
  .top-filter-field {
    @media (max-width: 1150.98px) {
      width: 100%;
    }
    .rs-stack-item {
      &:first-child {
        width: fit-content;
      }
      &:nth-child(2) {
        @media (max-width: 320.98px) {
          max-width: calc(100% - 50px);
        }
      }
      .calendar-ui {
        width: 100%;
        @media (max-width: 350.98px) {
          max-width: calc(100% - 16px);
        }
        @media (max-width: 340.98px) {
          max-width: calc(100% - 22px);
        }
        @media (max-width: 330.98px) {
          max-width: calc(100% - 32px);
        }
        @media (max-width: 320.98px) {
          max-width: calc(100% - 16px);
        }
        .rs-picker-default {
          @media (max-width: 1024.98px) {
            width: 100%;
          }
        }
      }
    }
  }
  .form-control {
    @media (max-width: 1150.98px) {
      width: calc(50% - 8px) !important;
    }
    @media (max-width: 470.98px) {
      width: 100% !important;
    }
  }
  button {
    @media (max-width: 1150.98px) {
      width: calc(33.33% - 12px) !important;
      max-width: 100%;
    }
    @media (max-width: 576.98px) {
      width: calc(50% - 8px) !important;
    }
  }
}
.timesheet-tab-wrapper {
  .MuiTabs-fixed {
    button {
      @media (max-width: 1150.98px) {
        padding-top: 0 !important;
      }
    }
  }
}
.timesheets-header {
  .top-filter-field {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 1150.98px) {
      width: 100%;
    }
  }
  .calendar-ui {
    width: 100%;
    .rs-picker-toggle-wrapper {
      @media (max-width: 1150.98px) {
        width: 100%;
      }
    }
  }
}

.add-time {
  textarea {
    background: none !important;
  }
}
