.page-wrapper {
    .top-bar{
        .searchbox{
            border: 1px solid var(--color-grey);
            border-radius: 10px;
            padding: 7px 10px;
            input{
                padding: 0;
            }
            button{
                font-size: 16px;
            }
            &:focus{
                border: 1px solid var(--color-green);
            }
        }
    }
  
    td{
        &.Active,&.active{
            color: var(--color-green) !important;
            font-weight: 500 !important;
        }
        &.Archive,&.archive{
            color: var(--color-grey) !important;
            font-weight: 500 !important;
        }
    }
  
    .table-action-btn {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        svg {
            font-size: 24px;
        }
        button {
            width: 40px;
            max-width: 40px;
            min-width: 40px;
            height: 40px;
        }
    }
  
    .incomplete-timesheets-table-listing {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
        .delete-wrapper {
            background: #C5E389;
            border-radius: 5px;
    
            .btn {
                font-size: 16px !important;
                max-width: 160px;
                height: 40px;
            }
        }
  
        .table-wrapper {
            .incomplete-timesheets-table{        
                thead {
                    tr {
                        th {
                            &:nth-child(1) {
                                min-width: 100px;
                                max-width: 200px;
                            }
                            &:nth-child(2) {
                                min-width: 150px;
                            }
                            &:last-child {
                                // text-align: center !important;
                                min-width: 150px !important;
                                max-width: 150px !important;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        &.Mui-selected,
                        &:hover {
                            background-color: #c5e38980 !important;
                        }
                    }
                    tr{
                        td{
                            .MuiAvatarGroup-root{
                                justify-content: flex-end;
                                cursor: pointer;
                                .MuiAvatar-root{
                                    background-color: var(--color-green);
                                    width: 30px;
                                    height: 30px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }