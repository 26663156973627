.page-wrapper {
  .select-peojects {
    max-width: 250px;
    min-width: 200px;
  }

  .d-none {
    display: none;
  }

  .d-block {
    display: block;
  }

  .table-wrapper {
    .todo-table {
      thead {
        tr {
          th {
            &:first-child {
              min-width: 120px;
              width: 120px;
              max-width: 120px;
            }
            &:nth-child(2) {
              min-width: 200px;
              width: 500px;
              max-width: 500px;
            }
            &:nth-child(3) {
              max-width: 150px;
              width: 120px;
            }
            &:nth-child(4) {
              width: 120px !important;
              min-width: 120px !important;
              max-width: 120px !important;
            }
            &:last-child {
              text-align: center;
              min-width: 100px !important;
              width: 100px;
            }
          }
        }
      }
      tbody {
        tr {
          &.Mui-selected,
          &:hover {
            background-color: #c5e38980 !important;
          }
        }
        tr {
          td {
            .MuiAvatarGroup-root {
              justify-content: flex-end;
              cursor: pointer;

              .MuiAvatar-root {
                background-color: var(--color-green);
                width: 30px;
                height: 30px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-list {
  list-style-type: none;
  margin: 0;
  padding: 0px;

  max-height: calc(100vh - 500px);
  overflow: scroll;
}

.tooltip-list li {
  margin: 5px;
  padding: 5px;
}

// .example {
//   background-color: #eee;
//   width: 200px;
//   height: 100px;
//   border: 1px dotted black;
//   overflow-y: scroll; /* Add the ability to scroll */
// }

/* Hide scrollbar for Chrome, Safari and Opera */
// .tooltip-list::-webkit-scrollbar {
//   display: none;
// }

.divider {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-top: 1px solid var(--color-light-grey);
}

.timeline-drawer {
  .MuiDrawer-paper {
    max-width: 850px !important;
    width: 100% !important;
    padding: 0;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    .close-timeline {
      position: absolute;
      right: 12px;
      top: 6px;
      font-size: 24px;
      transform: rotate(45deg);
      cursor: pointer;
      z-index: 5;
    }

    .timeline-tab {
      border-bottom: 1px solid #cccccc;
      .timeline-btn {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 38px;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: capitalize;
        color: var(--color-grey);
        min-width: 50px;
      }

      .MuiTabs-indicator {
        background-color: var(--color-green);
      }
    }

    .timeline-content {

      .btn {
        max-width: 100%;
        margin-top: 10px;
      }
      .checkbox-field {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      .select-filter {
        .css-reqq1a-control,
        .css-17cqwks-control {
          min-height: 50px;
        }
      }

      .timeline-container {
        padding: 12px;
        margin: 0;
      }

      .timeline-item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        position: relative;
        @media(max-width:768.98px){
          align-items: flex-start;
        }
      }

      .timeline-info {
        display: block;
        align-items: center;
        margin-right: 10px;
        white-space: nowrap;
      }

      .timeline-description {
        font-size: 16px;
        font-weight: 600;
      }

      .timeline-memo-button {
        margin-left: auto;
      }

      .timeline-memo {
        margin-top: 10px;
      }

      .memo-title {
        font-weight: 500;
        margin-right: 5px;
      }
    }
  }
}

.add-form-space {
  margin-bottom: 20px !important;
}

.to-do-items {
  display: flex;
  flex-wrap: nowrap;
}

.to-do-items button {
  white-space: nowrap;
}
.timeline-update-wrapper{
  display: flex; 
  align-items: flex-start;
  margin-top: 8px;
  @media(max-width:768.98px){
    flex-direction: column;
    margin-top: 0;
  }
}
.timeline-memo-button{
  position: relative !important;
  top: 4px;
  @media(max-width:768.98px){
    top: 0;
  }
}
.filter-drawer{
  .MuiDrawer-paper{
    padding: 0 !important;
  }
  &.to-do{
    .close-filter{
      top: 6px !important;
    }
    .filter-content{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.project-todo-module-wrapper{
  align-items: center;
  @media(max-width:1120.98px){
    flex-direction: column-reverse;
    align-items: baseline;
  }
  .content-header-right{
    @media(max-width:1120.98px){
      width: 100%;
    }   
    .searchbox{
      @media(max-width:1120.98px){
        width: calc(100% - 240px);
        .MuiInputBase-root{
          width: 100%;
        }
      }   
      @media(max-width:480.98px){
        width: 100%;
      }   
      .end-input-icon{
        button{
          @media(max-width:480.98px){
            width: fit-content !important;
            max-width: unset !important;
            // font-size: 24px;
          } 
        }
      }
    }
    .add-btn-to-do{
      @media(max-width:480.98px){
        width: 100% !important;
        max-width: calc(100% - 68px) !important;
      }   
    }
  }
}

.timeline-content{
  ul{
    li:first-child{
      .timeline-update-wrapper{
        margin-top: 4px;
        @media(max-width:768.98px){
          margin-top: -4px;
        }
      }
    }
  }  
}