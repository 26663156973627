.authentication{
    .authenication-form{
        .form-field{
            .form-control{
                // .select-time-zone{
                //     // .css-reqq1a-control, .css-17cqwks-control{
                //     //     min-height: 50px !important;
                //     //     border-color: var(--color-light-grey);
                //     // }
                //     div:nth-child(3){
                //         min-height: 50px !important;
                //         border-color: var(--color-light-grey);
                //     }
                // }
                .error-message{
                    color: red;
                    font-size: 14px;
                }
            }
        }
    }
}