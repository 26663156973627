.page-wrapper {
  .table-wrapper {
		.employee-table {
			thead {
				tr {
					th {
            &:nth-child(1) {
							min-width: 250px;
							max-width: 250px;
						}
            &:nth-child(2) {
							min-width: 120px;
						  max-width: 150px;
						}
            &:nth-child(3) {
							min-width: 100px;
						  max-width: 100px;
						}
            &:nth-child(4) {
              min-width: 150px;
            }
            // &:nth-child(5) {
            //   min-width: 220px;
            // }
						&:last-child {
              text-align: center;
							min-width: 80px !important;
						}
					}
				}
			}
		}
	}
}

.head-styles{
  min-width: 174px;
  white-space: nowrap;
}

.filter-drawer {
  .MuiDrawer-paper {
    max-width: 350px;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    .close-filter {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 24px;
      transform: rotate(45deg);
      cursor: pointer;
      z-index: 5; 
    }

    .filter-tab {
      border-bottom: 1px solid #CCCCCC;
      .filter-btn {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 38px;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: capitalize;
        color: var(--color-grey);
        min-width: 50px;
      }

      .MuiTabs-indicator {
        background-color: var(--color-green);
      }
    }

    .filter-content {
      margin-top: 20px;

      .forgot-password {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: var(--color-black);
        text-align: right;
        cursor: pointer;
      }

      .btn {
        max-width: 100%;
        margin-top: 10px;
      }
      .checkbox-field{
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
      .select-filter {

        .css-reqq1a-control,
        .css-17cqwks-control {
          min-height: 50px;
        }
      }
    }
  }
}

.modal-wrapper {
  .css-reqq1a-control,
  .css-17cqwks-control {
    min-height: 50px;
  }

  .form-control {
    margin-bottom: 0;
  }

  .add-employee {
    color: var(--color-black);
    padding: 0;
    background-color: transparent !important;
    border: 0 !important;
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
  }
}
.user-module-drawer{
  .MuiDrawer-paper{
    .close-filter{
      right: 8px;
      top: 5px;
    }
    .filter-content{
      padding: 0 20px;
    }
  }
}

.users-module-wrapper{
  align-items: center;
  .MuiTabs-root{
    margin-bottom: 0;
  }
  @media(max-width:991.98px){
    flex-direction: column-reverse;
  }
  .top-filter-field{
    @media(max-width:991.98px){
      width: 100%;
    }
  }
  .content-header-right{
    @media(max-width:991.98px){
      width: 100%;
    }
    .top-filter-field{
      @media(max-width:991.98px){
        max-width: calc(100% - 66px);
      }
      .MuiInputBase-root{
        @media(max-width:991.98px){
          width: 100%;
        }
      }
    }
  }
}

.account-wrapper{

  &.edit-employee-wrapper{
    .account-content {
      min-height: calc(100vh - 160px);
      @media(max-width:900.98px){
        min-height:auto;
      }
    }
    .account-tab {
      min-height: calc(100vh - 274px);
      @media(max-width:900.98px){
        min-height:auto;
      }
    }
  }
}