.settings-card-wrapper {
  .settings-card {
    background: var(--color-black);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    width: 100%;
    display: block;
    &:hover {
      cursor: pointer;
    }

    .settings-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    @media (min-width: 1200px) and (max-width: 1348px) {
      .settings-content {
        p {
          font-size: 15px;
        }
        h6 {
          font-size: 16px;
        }
      }
    }

    @media (min-width: 1348px) and (max-width: 1420px) {
      .settings-content {
        p {
          font-size: 16px;
        }
        h6 {
          font-size: 18px;
        }
      }
    }

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      @media (max-width: 1280.98px) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      margin-top: 10px;
      display: block;
      line-height: 50px;

      @media (max-width: 1280.98px) {
        font-size: 35px;
        line-height: 40px;
      }
    }

    svg {
      color: var(--color-white);
      width: 70px;
      height: 70px;
      color: var(--color-green);
      stroke-width: 1;

      @media (max-width: 1280.98px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.select-time {
  .css-reqq1a-control,
  .css-17cqwks-control {
    min-height: 50px !important;
    // max-width: 1000px !important;
  }
}

.select-days {
  .css-reqq1a-control,
  .css-17cqwks-control {
    min-height: 50px !important;
    width: 100%;
  }
}

.screenshot-setting-table {
  max-height: calc(100vh - 290px);
  overflow: scroll;
  table {
    thead {
      position: sticky;
      z-index: 999;
      top: 0;
      left: 0;
    }
  }
}

.setting-back-btn{
  @media(max-width:991.98px){
    display: none !important;
  }
}