.page-wrapper {
  .mb-24 {
    margin-bottom: 24px;
  }

  .activity-card {
    &:hover {
      cursor: pointer;
    }

    @media (min-width: 1200px) and (max-width: 1348px) {
      .activity-content {
        p {
          font-size: 15px;
        }
        h6 {
          font-size: 16px;
        }
      }
    }

    @media (min-width: 1348px) and (max-width: 1420px) {
      .activity-content {
        p {
          font-size: 16px;
        }
        h6 {
          font-size: 18px;
        }
      }
    }
  }

  .text-desc {
    margin: revert !important;
    white-space: wrap;
    max-width: 350px;
  }
.bnb-project-svg{
  // border: 2px solid red;
  width: 74px !important;
}
@media (min-width:420px) and (max-width: 995px) {
  .bnb-project-svg{
    // border: 2px solid blue;
    width: 55px !important;
  }
}
@media (min-width:360px) and (max-width: 420px) {
  .bnb-project-svg{
    // border: 2px solid blue;
    width: 65px !important;
  }
}
  // top bar
  .report-top-bar {
    margin-bottom: 30px;
    .MuiGrid-container {
      align-items: flex-end;
    }
    .css-e83jn5-MuiStack-root > .MuiTextField-root {
      max-width: 312px;
      min-width: 0;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
    }

    .report-top-filter {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;
      .css-j204z7-MuiFormControlLabel-root {
        margin-right: 4px !important;
        margin-left: 0px !important;
      }
      .MuiFormControlLabel-root {
        margin: 0px !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border-radius: 10px;
        border: 1px solid #777777;
      }
      .MuiInputBase-input {
        font-family: "Poppins";
        color: #777777;
        font-size: 15px;
        padding: 14px 10px 9px 9px;
      }
      .checkbox-field {
        padding: 0px 6px 0px 0px;
        color: var(--color-grey);
        &.Mui-checked {
          color: var(--color-green);
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .report-btn-icon {
      button {
        text-transform: none;
        display: flex;
        gap: 8px;
        background-color: #8cc714;
        color: white;
        font-size: 16px;
        height: 40px;
        max-width: 116px;
        border-radius: 10px;
        @media (max-width: 650px) {
          font-size: 14px;
          gap: 5px;
          flex: 1;
          max-width: 100%;
        }
        // &:last-child {
        //   width: 50px;
        // }
        &.filter-btn-icon {
          width: 50px;
        }
      }
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      svg {
        width: 22px;
        height: 20px;
      }
    }
    .report-select-date {
    
      width: 180px;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      border-radius: 10px;
      .css-reqq1a-control {
        background-color: transparent;
        border: 1px solid #ccc;
      }
      @media (max-width: 1163.98px) {
        min-width: 90px;
        font-size: 14px;
      }
    }
    .report-select-emp {
      width: 355px !important;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      border-radius: 10px;
      .css-reqq1a-control {
        background-color: transparent;
        border: 1px solid #ccc;
      }
      @media (max-width: 1163.98px) {
        min-width: 90px;
        font-size: 14px;
      }
    }
    .report-select-options {
      width: 210px;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      border-radius: 10px;

      @media (max-width: 1163.98px) {
        min-width: 90px;
        white-space: nowrap;
        font-size: 14px;
      }
    }
    .report-select-options2 {
        width: 100%;
        // max-width: calc(100% - 53%);
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        border-radius: 10px;
        flex: 1;
        @media(min-width:601px) and (max-width: 1024px){
        flex: auto;
      }
    }
  }

  .MuiTabs-root {
    margin-bottom: 25px;
  }
}

// table
.report-table-wrapper {
  table {
    .MuiTableCell-root {
      font-family: "Poppins";
    }
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 280px;
          }
          &:nth-child(2) {
            min-width: 300px;
          }
          &:nth-child(3) {
            min-width: 300px;
          }
          // &:nth-child(4) {
          //   min-width: 160px;
          // }
        }
      }
    }
    thead {
      background-color: #8cc71480;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      tr {
        th {
          color: var(--color-black);
          font-weight: 500;
          font-size: 15px;
          line-height: 30px;
          padding: 10px 20px;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    tbody {
      &:first-child {
        border-radius: 5px;
      }
      tr {
        td {
          font-weight: 400;
          font-size: 15px;
          line-height: 27px;
          color: var(--color-grey);
          padding: 10px 20px;
          border-bottom: 1px solid rgba(119, 119, 119, 0.2);
          &.td-border-none {
            border: none;
          }
        }
        &:first-child {
          background-color: var(--color-black);
        }
      }
      .report-table-avatar {
        // display: flex;
        align-items: center;
        gap: 10px;
      }
      .report-table-row {
        background-color: var(--color-black);
        color: var(--color-white);

        .report-table-date {
          color: var(--color-white);
          border-radius: 5px;
          font-size: 15px;
        }
      }
      .project-table-row {
        background-color: var(--color-black);
        .project-table-avatar {
          color: var(--color-white);
          border-radius: 5px;
          font-size: 15px;
        }
      }
    }
  }
  .report-table-hidden {
    &:last-child {
      display: none;
    }
  }
  .project-total {
    font-weight: 500;
  }
}

.activity-left-space {
  padding-right: 45px !important;
  padding-left: 0px !important;
}
.time-space {
  padding-right: 40px !important;
}
.initial-avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 15px !important;
  line-height: 30px !important;
}

.time-space-zero {
  padding-right: 0px !important;
}

.filter-switch {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  margin-left: 4px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.saved-filter-wrapper {
  .saved-filter-list {
    display: flex;
    padding: 6px 20px;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    &:hover {
      cursor: pointer;
      background: rgba(140, 199, 20, 0.2);
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    .action-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      button {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        padding: 0;
        margin: 0 !important;
      }
    }
  }
  .selected {
    background-color: #8cc71480;
    border-radius: 0;
  }
}
.drawer-padding {
  padding: 0 20px !important;
}

.content-header {
  .report-select-date {
    @media (max-width: 576.98px) {
      width: 100% !important;
    }
  }
}
.time-activity-wrapper {
  .report-top-filter {
    @media (max-width: 1024.98px) {
      width: 100%;
    }
    .report-select-date {
      @media (max-width: 1024.98px) {
        width: calc(50% - 64px);
      }
      @media (max-width: 991.98px) {
        width: calc(50% - 10px);
      }
    }
    .calendar-ui {
      @media (max-width: 1024.98px) {
        width: calc(50% - 72px);
        .rs-picker-default {
          width: 100%;
        }
      }
      @media(max-width:576.98px){
        width: 100%;
      }
    }
  }
  .content-header-right {
    @media (max-width: 991.98px) {
      width: 100%;
      max-width: 100% !important;
    }
    button {
     
      @media (max-width: 991.98px) {
        width: 100%;
        max-width: calc(50% - 41px) !important;
      }
      @media(max-width:576.98px){
        width: 100%;
        max-width: calc(50% - 8px) !important;   
      }
    }
  }
}

.seceenshot-report-module-wrapper {
  .report-top-filter {
    @media (max-width: 1010.98px) {
      width: 100%;
    }
  }
  .calendar-ui {
    @media (max-width: 1010.98px) {
      max-width: 100%;
      width: 100%;
    }
    .rs-picker-toggle-wrapper {
      @media (max-width: 1010.98px) {
        width: 100%;
      }
    }
  }
  .content-header-right {
    
    @media (max-width: 1010.98px) {
      width: 100%;
    }
    .report-select-options {
      @media (max-width: 1010.98px) {
        width: 100%;
        max-width: calc(50% - 8px);
      }
      @media (max-width: 412.98px) {
        max-width: 100%;
      }
    }
  }
}

.incomplete-timesheet-report-module-wrapper {
  .report-top-filter {
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  .calendar-ui {
    @media (max-width: 991.98px) {
      width: calc(100% - 66px) !important;
    }
    @media (max-width: 576.98px) {
      width: 100% !important;
    }
    .rs-picker-toggle-wrapper {
      @media (max-width: 991.98px) {
        width: 100%;
      }
    }
  }
  .report-select-date {
    @media (max-width: 991.98px) {
      width: 100% !important;
      max-width: calc(100% - 210px);
    }
    @media (max-width: 480.98px) {
      max-width: 100%;
    }
  }
  .content-header-right{
    
    @media(max-width:768.98px){
      width: 100%;
    }
    button{
      @media(max-width:768.98px){
        width: 100% !important;
        max-width: calc(50% - 8px) !important;
      }
      @media(max-width:576.98px){
        max-width: calc(50% - 41px) !important;
      }
      &.mobile-sorting-btn{
        @media(max-width:576.98px){
          width: auto !important;
          max-width: auto !important;
        }
      }
    }
  }
}

.billable-report-module-wrapper {
  align-items: flex-start;
  .report-top-filter {
    width: 100%;
    align-items: flex-start !important;
    @media (max-width: 600.98px) {
      flex-direction: column;
    }
    .report-select-options {
      @media (max-width: 990.98px) {
        flex: 1;
        width: 100%;
      }
    }
  }
  .calendar-ui {
    @media (max-width: 1110.98px) {
      max-width: calc(100% - 52px);
      width: 100%;
    }
    @media (max-width: 991.98px) {
      max-width: 100%;
    }
    @media (min-width: 601px) and (max-width: 1024px) {
      flex: 1;
    }
    .rs-picker-default {
      width: 100%;
    }
  }
  .content-header-right {
    @media (max-width: 991.98px) {
      width: 100%;
      justify-content: flex-start !important;
    }
    button {
      @media (max-width: 991.98px) {
        // max-width: calc(50% - 8px) !important;
        width: 100%;
      }
      @media (max-width: 412.98px) {
        max-width: 100% !important;
      }
    }
  }
}
.report-drawer-panel {
  .close-filter {
    right: 8px !important;
    top: 6px !important;
  }
}

.back-btn {
  &.MuiSvgIcon-root {
    &.MuiSvgIcon-fontSizeMedium {
      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }
}


.desktop-sorting-btn {
  @media (max-width: 576.98px) {
    display: none !important;
  }
}
.mobile-sorting-btn {
  @media (min-width: 576.98px) {
    display: none !important;
  }
}

@media(min-width:1199px) and (max-width:1366px){
  .page-wrapper {
    &.close {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            flex: none;
          }
          .report-select-options2{
            flex: 1;
          }
        }
      }
    }
    &.open {
      .billable-report-module-wrapper{
        &.report-top-bar{
          // .calendar-ui {
          //   flex: none;
          // }
          .report-select-options2{
            flex: 1 0 auto;
            width: 70%;
          }
        }
      }
    }
  }
}
@media(min-width:991px) and (max-width:1024.98px){
  .page-wrapper {
    &.close {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            flex: 1;
          }
          .report-select-options{
            flex: 1;
          }
        }
      }
    }
    &.open {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            flex: 1;
            width: auto;
          }
          .report-select-options{
            flex: 1;
          }
        }
      }
    }
  }
}
@media(min-width:1025px) and (max-width:1366.98px){
  .page-wrapper {
    &.close {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            // flex: 1;
            width: auto;
          }
          .report-select-options{
            // flex: 1;
          }
          .report-select-options2{
            flex: auto;
            width: 100%;
          }
        }
      }
    }
    &.open {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            // flex: 1;
            width: auto;
          }
          .report-select-options{
            flex: 1;
          }
          .report-select-options2{
            flex: auto;
            width: 55%;
          }
        }
      }
    }
  }
}
/////////////
@media(min-width:1367px) and (max-width:1499.98px){
  .page-wrapper {
    &.close {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            flex: none;
          }
          .report-select-options{
            flex: none;
          }
          .report-select-options2{
            flex: 1;
            width: 90%;
          }
        }
      }
    }
    &.open {
      .billable-report-module-wrapper{
        &.report-top-bar{
          .calendar-ui {
            // flex: 1;
            width: auto;
          }
          .report-select-options{
            flex: 1;
          }
          .report-select-options2{
            flex: auto;
            width: 68%;
          }
        }
      }
    }
  }
}