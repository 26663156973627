// download app screen
.download-wrapper{
    padding: 12px;
    .site-logo{
        background-color: var(--color-black);
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 5px solid var(--color-green);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin: 0 auto;
        margin-bottom: 25px;
        @media(max-width:1560.98px){
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }
        img{
          width: 100%;
        }
    }
    .download-title{
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 10px;
        text-align: center;
        @media(max-width:1560.98px){
          font-size: 35px;
          line-height: 50px;
        }
    }
    .download-para{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
        display: block;
        text-align: center;
        @media(max-width:1560.98px){
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 20px;
        }
    }
    .use-text{
      cursor: pointer;
      p {
        color: var(--color-grey);
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid var(--color-grey);
      }
    }

    .download-details{
        width: 70%;
        margin: 0px auto;
        margin-top: 40px !important;

        .MuiTabs-root{
            margin-bottom: 0px;
        }

        .li-items {
            margin-bottom: 14px;
            &::marker, h4{
                font-size: 22px;
                font-weight: 500;   
            }
        }
        
    }
}

.step-container{
  margin-bottom: 20px;
  .step-text{
    margin-bottom: 10px;

    h4{
      white-space: wrap;
    }
    p{
      margin: 0;
      margin-left: 20px;
      font-size: 18px;
    }
  }
}