// login screen
.authentication{
  .authentication-img{
    height: 100vh;
    background: rgba(140, 199, 20, 0.15);
    border-radius: 0px 25px 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:950.98px){
      height: 100%;
    }
    @media(max-width:899.98px){
      display: none;
    }
    @media (orientation: landscape){
      // height: 100%;
      // display: none;
    }
    img{
      max-width: 672px;
      width: 100%;
      @media(max-width:1560.98px){
        max-width: 500px;
      }
      @media(max-width:1024.98px){
        padding:30px;
      }
    }
  }
  .auth{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .authenication-form{
      max-width: 560px;
      width: 100%;
      margin: auto;
      @media(max-width:1200.98px){
        padding: 30px;
      }
      // @media(max-width:1020.98px){
      //   padding: 30px;
      //   height: 100vh;
      //   overflow: auto;
      // }
      @media(max-width:899.98px){
        display: flex;
        align-items: center;
        padding: 24px;
        height: 100vh;
      }
      @media (orientation: landscape) and (max-width:899.98px){
        height: auto;
      }
      @media(max-width:375.98px){
        display: flex;
        align-items: center;
        height: auto;
      }
      .site-logo{
        background-color: var(--color-black);
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 5px solid var(--color-green);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin: 0 auto;
        margin-bottom: 25px;
        @media(max-width:1560.98px){
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }
        img{
          width: 100%;
        }
      }
      .auth-title{
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 10px;
        text-align: center;
        @media(max-width:1560.98px){
          font-size: 35px;
          line-height: 50px;
        }
      }
      .auth-para{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
        display: block;
        text-align: center;
        @media(max-width:1560.98px){
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
      .forgot-password{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: var(--color-black);
        text-align: right;
        cursor: pointer;
      }
      .remember-forgot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        @media(max-width:375.98px){
          flex-direction: column;
          align-items: baseline;
          gap: 16px;
        }
      }
      // .btn{
      //   max-width: max-content;
      //   padding: 10px 32px;
      //   text-decoration: none; 
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   margin-left: 32px;
      // }
      .btn{
        max-width: 100%;
        margin-top: 20px;
      }
    }
    .back-link{
      position: absolute;
      top: 30px;
      left: 20px;
      width: 30px;
      height: 30px;
      color: var(--color-black);
      display: block;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      cursor: pointer;
    }
  }
}